import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Vote = ({setConnectWallet, connected }) => {
  const [page, setPage] = useState(1);
  const header = [
    {
      id: 1,
      name: "Node ID",
    },
    {
      id: 2,
      name: "veQI weight",
    },
    {
      id: 3,
      name: "Delegated AVAX",
    },
  ];

  const nodes = [
    {
      id: 1,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49Ko",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 2,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBkg49Ko",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 3,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49fKo",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 4,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49Ke",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 2,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBkg49Ko",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 3,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49fKo",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 4,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49Ke",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 2,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBkg49Ko",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 3,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49fKo",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 4,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49Ke",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 2,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBkg49Ko",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 3,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49fKo",
      weight: "6.34",
      avax: "53,443",
    },
    {
      id: 4,
      node: "NodeID-2eJ2XJDpUagguhE9wKFR9JUxYNaBk49Ke",
      weight: "6.34",
      avax: "53,443",
    },
  ];
  const [checked, setChecked] = useState([]);
  const [c, setC] = useState("");

  const stake = (
    <section
      className="lg:w-[900px] w-[100%] flex flex-col items-center justify-center rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] 
    p-[1.5rem] lg:p-[2.5rem]"
    >
      <section className="flex flex-row items-center justify-between w-[100%]">
        <img src="./images/vt.svg" className="lg:w-[369px] lg:flex hidden" />

        <div
          className={`dark:bg-[#001016] bg-white p-[2.25rem] rounded-[.75rem] w-[100%] 
          lg:w-[50%] ${
            !connected && "h-[189px]"
          }  flex flex-col items-center justify-center`}
          style={{
            boxShadow: "rgb(0, 179, 237) 0px 0px 12px",
          }}
        >
          <img
            src="./images/vt2.png"
            className="w-[80px] relative mt-[-80px] mb-[30px]"
          />
          {!connected && (
            <section className="flex flex-col items-center w-[100%]">
              <h1 className="text-[16px] font-[400] text-black dark:text-white pb-[15px]">
                Please connect your wallet to stake QI.
              </h1>

              <button
                onClick={() => {
                  setConnectWallet(true);
                }}
                className="bg-transparent border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[400] hover:bg-[#00b3ed] rounded-[.75rem] py-[5px] flex flex-row items-center justify-center
      hover:text-white w-[148px]"
              >
                Connect wallet
              </button>
            </section>
          )}

          {connected && (
            <section className="flex flex-col items-start w-[100%] ">
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1 className="text-[1.25rem] text-left">Total supply</h1>
                <h1 className="flex flex-row items-center text-[#00b3ed] text-[1.25rem] font-[600]">
                  15,866.5M
                  <span className="pl-[5px] text-[0.9375rem] font-[400] mt-[5px]">
                    veQI
                  </span>{" "}
                </h1>
              </div>

              <div className="flex flex-row items-center justify-between w-[100%] py-[10px]">
                <h1 className="text-[1.25rem] ">My balance</h1>
                <h1 className="flex flex-row items-center text-[#00b3ed] text-[1.25rem] font-[600]">
                  0.00
                  <span className="pl-[5px] text-[0.9375rem] font-[400] mt-[5px]">
                    veQI
                  </span>{" "}
                </h1>
              </div>

              <div className="w-[100%] h-[1px] bg-[#00b3ed]"></div>

              <div className="flex flex-row items-center justify-between w-[100%] py-[10px]">
                <h1 className="text-[1.25rem] ">QI Staked</h1>
                <h1 className="flex flex-row items-center text-[#00b3ed] text-[1.25rem] font-[600]">
                  0.00
                  <span className="pl-[5px] text-[0.9375rem] font-[400] mt-[5px]">
                    veQI
                  </span>{" "}
                </h1>
              </div>

              <section className="flex flex-row items-center justify-between w-[100%]">
                <button
                  className="w-[49%] flex flex-row items-center justify-center h-[45px] hover:bg-[#00b3ed]
              border-solid border-[1px] border-[#00b3ed] rounded-[.75rem] text-[1.125rem] cursor-pointer"
                >
                  Stake
                </button>

                <button
                  className="w-[49%] flex flex-row items-center justify-center h-[45px] text-[#707070]
              border-solid border-[1px] border-[#707070] rounded-[.75rem] text-[1.125rem] cursor-not-allowed"
                >
                  Unstake
                </button>
              </section>
            </section>
          )}
        </div>
      </section>

      <h1 className="text-[14px] font-[400] text-[#949494] dark:text-[#b9b9b9] pt-[30px]">
        veQI represents your voting power for AVAX delegations on BENQI Liquid
        Staking. It starts accruing value the moment it is staked and resets
        once unstaked. veQI is not transferable nor tradeable.{" "}
        <a
          href="https://docs.benqi.fi/benqi-liquid-staking/veqi"
          className="underline"
        >
          {" "}
          Read more.
        </a>
      </h1>
    </section>
  );

  const vote = (
    <section className="lg:w-[900px] w-[100%] flex flex-col items-center justify-center rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] p-[1.5rem] lg:p-[2.5rem]">
      {connected && (
        <div
          className={`dark:bg-[#001016] bg-white p-[2.25rem] rounded-[.75rem] w-[100%] 
           flex flex-col items-center justify-center my-[50px]`}
          style={{
            boxShadow: "rgb(0, 179, 237) 0px 0px 12px",
          }}
        >
          <img
            src="./images/vt2.png"
            className="w-[80px] relative mt-[-80px] mb-[30px]"
          />

          <section className="flex flex-col items-start w-[100%] ">
            <div className="flex flex-row items-center justify-between w-[100%]">
              <h1 className="text-[1.25rem] text-left">Total supply</h1>
              <h1 className="flex flex-row items-center text-[#00b3ed] text-[1.25rem] font-[600]">
                15,866.5M
                <span className="pl-[5px] text-[0.9375rem] font-[400] mt-[5px]">
                  veQI
                </span>{" "}
              </h1>
            </div>

            <div className="flex flex-row items-center justify-between w-[100%] py-[10px]">
              <h1 className="text-[1.25rem] ">My balance</h1>
              <h1 className="flex flex-row items-center text-[#00b3ed] text-[1.25rem] font-[600]">
                0.00
                <span className="pl-[5px] text-[0.9375rem] font-[400] mt-[5px]">
                  veQI
                </span>{" "}
              </h1>
            </div>

            <div className="w-[100%] h-[1px] bg-[#00b3ed]"></div>

            <div className="flex flex-row items-center justify-between w-[100%] py-[10px]">
              <h1 className="text-[1.25rem] ">QI Staked</h1>
              <h1 className="flex flex-row items-center text-[#00b3ed] text-[1.25rem] font-[600]">
                0.00
                <span className="pl-[5px] text-[0.9375rem] font-[400] mt-[5px]">
                  veQI
                </span>{" "}
              </h1>
            </div>

            <section className="flex flex-row items-center justify-between w-[100%]">
              <button
                className="w-[49%] flex flex-row items-center justify-center h-[45px] hover:bg-[#00b3ed]
              border-solid border-[1px] border-[#00b3ed] rounded-[.75rem] text-[1.125rem] cursor-pointer"
              >
                Stake
              </button>

              <button
                className="w-[49%] flex flex-row items-center justify-center h-[45px] text-[#707070]
              border-solid border-[1px] border-[#707070] rounded-[.75rem] text-[1.125rem] cursor-not-allowed"
              >
                Unstake
              </button>
            </section>
          </section>
        </div>
      )}

      <h1 className="text-[26px] pb-[20px]">
        Vote for your Avalanche validator of choice using veQI
      </h1>
      <section className="dark:bg-[#001016] bg-white w-[100%] flex flex-col items-start p-[1.5rem] rounded-[.75rem]">
        <h1 className="text-[16px] pb-[20px]">
          Select from the list below or enter Node ID
        </h1>
        <div className="flex flex-row items-center px-[15px] border-solid border-[1px] border-[#00b3ed] rounded-[.75rem] w-[100%] h-[42px]">
          <FontAwesomeIcon
            icon={faSearch}
            className="pr-[10px] text-[#00b3ed]"
          />
          <input
            className="placeholder:text-[#00b3ed] w-[100%] bg-transparent"
            placeholder="Search for node ID"
          />
        </div>

        <section className="flex flex-row items-center justify-between w-[100%] mt-[10px] dark:bg-[#001b24] bg-[#d7edfd] px-[.75rem] py-[.25rem] rounded-[.25rem]">
          {header.map((us, id) => (
            <div
              key={id}
              className={`${
                us.id === 1
                  ? "w-[50%] flex flex-row items-center justify-start pl-[20px]"
                  : "w-[20%] hidden lg:flex flex-row items-center justify-end"
              } text-[14px] font-[700]`}
            >
              {us.name}
            </div>
          ))}
        </section>
        <section className="h-[200px] overflow-y-scroll w-[100%] flex flex-col items-start pt-[10px]">
          {nodes.map((us, id) => (
            <div
              key={id}
              className="flex flex-row items-center justify-between w-[100%] text-[14px] font-[400] cursor-pointer
                hover:dark:bg-[#074b61] hover:bg-[#ccf0fc] rounded-[.375rem]  px-[.75rem] py-[.25rem]"
              onClick={() => {
                const isChecked = checked[us.ndoe];
                if (isChecked) {
                  const newChecked = { ...checked };
                  delete newChecked[us.ndoe];
                  setChecked(newChecked);
                } else {
                  setChecked({ ...checked, [us.ndoe]: true });
                }

                setC(us.node);
              }}
            >
              <h1 className="w-[100%] lg:w-[50%] text-left flex flex-row items-center">
                <div className="bg-[#a1e8ff] dark:bg-[#003f54] rounded-[.375rem] w-[21px] h-[21px] mr-[5px] flex flex-row items-center justify-center">
                  {checked && checked[us.ndoe] ? (
                    <i class="bx bx-check text-white text-[15px]"></i>
                  ) : (
                    ""
                  )}
                </div>
                <h1 className="w-[90%]">{us.node}</h1>
              </h1>
              <h1 className="w-[20%] lg:flex hidden flex-row items-center justify-end">
                {us.weight}%
              </h1>
              <h1 className="w-[20%] hidden lg:flex flex-row items-end justify-end ">
                {us.avax}
              </h1>
            </div>
          ))}
        </section>

        <div className="flex flex-row items-center justify-center w-[100%] py-[20px]">
          {connected ? (
            <button
              className="lg:w-[219px] flex flex-row items-center justify-center h-[45px] text-[#707070]
              border-solid border-[1px] border-[#707070] rounded-[.75rem] text-[1.125rem] cursor-not-allowed"
            >
              Vote
            </button>
          ) : (
            <button
              onClick={() => {
                setConnectWallet(true);
              }}
              className="bg-transparent border-solid border-[1px] border-[#00b3ed] text-black dark:text-white
         text-[16px] font-[400] hover:bg-[#00b3ed] rounded-[.75rem] py-[5px] flex flex-row items-center justify-center
      hover:text-white lg:w-[219px] w-[100%]"
            >
              Connect wallet to vote
            </button>
          )}
        </div>
      </section>
    </section>
  );

  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
        outfit dark:bg-[#003f54] px-[20px] lg:px-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <h1 className="text-[30px] font-[400]">Stake QI for veQI</h1>
      <h1 className="text-[16px] font-[300] pb-[30px]">
        Stake QI and Vote to receive AVAX delegations to your Avalanche
        validator of choice.
      </h1>

      <section className="flex flex-row items-center rounded-[1rem] border-solid border-[2px] border-[#00b3ed] h-[48px] w-[100%] lg:w-[320px] p-[1px] mb-[60px]">
        <button
          onClick={() => {
            setPage(1);
          }}
          className={`${
            page === 1
              ? "bg-[#00b3ed] text-black"
              : "bg-transparent text-[#00b3ed]"
          } w-[50%] h-[40px] flex flex-row items-center justify-center rounded-[.75rem] text-[20px]`}
        >
          Stake
        </button>
        <button
          onClick={() => {
            setPage(2);
          }}
          className={`${
            page === 2
              ? "bg-[#00b3ed] text-black"
              : "bg-transparent text-[#00b3ed]"
          } w-[50%] h-[40px] flex flex-row items-center justify-center rounded-[.75rem] text-[20px]`}
        >
          Vote
        </button>
      </section>

      {page === 1 ? stake : vote}
    </body>
  );
};

export default Vote;
