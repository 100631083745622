
const ov = [
    {
        id: 1,
        name: "AVAX",
        icon: "https://app.benqi.fi/svgs/tokens/avax.svg",
        value: "30.00",
        apy: "2.03",
        supply: "1.36M",
        vol: "40.86M",
        apy2: "-2.55",
        borrow: "717.85k",
        borrowVol: "21.56M",
        liq: "600k",
        liqVol: "19.31M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
    {
        id: 2,
        name: "BTC",
        icon: "https://app.benqi.fi/svgs/tokens/avax.svg",
        value: "30.00",
        apy: "2.03",
        supply: "1.36M",
        vol: "40.86M",
        apy2: "-2.55",
        borrow: "717.85k",
        borrowVol: "21.56M",
        liq: "600k",
        liqVol: "19.31M",
        distributionApy: "0.21",
        distributionApy2: "1.82",
        totalSupply: "1,358,940.04",
        totalBorrow: "717,959.72",
        supplier: "5",
        borrows: "5",
        reserve: "5",
        collateral: "5",
        borrowCap: "5",
        totalLiq: "5",
        totalLiqVol: "5",
        hSupply: "5",
        hborrow: "5",
        supplyQi: "5",
        supplyVal: "5",
        borrowQi: "5",
        totalb: "5",
        borrowQiTotal: "5",
        minted: "5",
        rate: "5"
    },
]

export default ov