import StakeSide from "./StakeSide";
import { Outlet } from "react-router-dom";
import {
  Modal,
  ModalContent,
  ModalBody,
  Drawer,
} from "@chakra-ui/react";
import Connect from "../Connect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const StakeLayout = ({
  addy,
  theme,
  setTheme,
  onClose,
  connectWallet,
  setConnectWallet,
  showSide,
  setShowSide,
  connected,
  showDetails,
  setShowDetails,
  setConnected,
}) => {
  return (
    <body className="flex flex-col lg:flex-row items-start w-[100%]">
      <div className="lg:hidden flex flex-row items-center justify-between w-[100%] p-[20px] dark:bg-[#161616] bg-[#d7edfd]">
        <img
          src={theme === "dark" ? "./images/li2.svg" : "./images/li.svg"}
          className="w-[44px]"
        />
        {!connected && (
          <button
            className="lg:bg-white lg:dark:bg-[#000000] border-solid border-[1px] border-[#00b3ed] text-black lg:dark:text-white
         text-[16px] font-[500] w-[148px] lg:w-[100%] lg:hover:bg-[#00b3ed] bg-[#00b3ed]
        rounded-[.375rem] py-[5px] flex flex-row items-center justify-center hover:dark:text-white"
            onClick={() => {
              setConnectWallet(true);
            }}
          >
            Connect Wallet
          </button>
        )}

        <i
          class="bx bx-menu text-[40px] dark:text-white cursor-pointer"
          onClick={() => {
            setShowSide(true);
          }}
        ></i>
      </div>
      <section className="w-[17%] hidden lg:fixed lg:block">
        <StakeSide
          addy={addy}
          setTheme={setTheme}
          theme={theme}
          connected={connected}
          setConnectWallet={setConnectWallet}
          connectWallet={connectWallet}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          setConnected={setConnected}
        />
      </section>

      <section className="w-[100%] lg:pl-[17%]">
        <Outlet />
      </section>

      <Modal isOpen={showSide} onClose={showSide} className="lg:hidden">
        <ModalContent className="flex flex-col items-center w-[100%] min-h-[100vh] z-50 fixed lg;hidden">
          <div
            className="bg-white dark:bg-[#334758] bg-opacity-40 dark:bg-opacity-90 w-[100%] min-h-[100vh] absolute z-40"
            onClick={() => {
              setShowSide(false);
            }}
          ></div>

          <ModalBody className="flex z-50 flex-col items-center justify-start pt-[150px] min-h-[100vh] w-[100%]">
            <Drawer
              placement="left"
              isOpen={showSide}
              onClose={onClose}
              className="fixed left-0 lg:hidden flex-col items-center justify-center z-50 bg-red-500 pb-[20px] min-h-[100vh]"
            >
              <section className="flex flex-col items-center w-[80%] h-[100%] fixed top-0 min-h-[100vh]">
                <StakeSide
                  setTheme={setTheme}
                  theme={theme}
                  setShowSide={setShowSide}
                  setConnectWallet={setConnectWallet}
                  connectWallet={connectWallet}
                  connected={connected}
                  addy={addy}
                  setConnected={setConnected}
                  setShowDetails={setShowDetails}
                />
              </section>
            </Drawer>
          </ModalBody>
        </ModalContent>
      </Modal>

      {connected && (
        <Modal isOpen={showDetails} onClose={showDetails} className="">
          <ModalContent className="flex flex-col items-center w-[100%] min-h-[100vh] z-50 fixed">
            <div
              className="bg-white dark:bg-[#334758] bg-opacity-90 dark:bg-opacity-90 w-[100%] min-h-[100vh] absolute z-40"
              onClick={() => {
                setShowDetails(false);
              }}
            ></div>

            <ModalBody className="flex z-50 flex-col items-center justify-start pt-[150px] min-h-[100vh] w-[100%]">
              <section
                className="dark:bg-[#001016] bg-white border-solid border-[2px] border-[#00b3ed] flex flex-col items-center 
            lg:w-[600px] w-[100%] rounded-[.75rem] p-[15px]"
              >
                <div className="flex flex-row items-end justify-end w-[100%]">
                  <FontAwesomeIcon
                    icon={faX}
                    className="dark:text-white text-black text-[18px] cursor-pointer"
                    onClick={() => {
                      setShowDetails(false);
                    }}
                  />
                </div>
                <h1 className="text-[1.688rem] font-[300] text-black dark:text-white">
                  Account
                </h1>

                <section
                  className="flex-col items-center justify-between rounded-[0.1875rem]
        dark:bg-transparent dark:text-white w-[100%] px-[1.25rem] my-[20px] bg-[#d7edfd] py-[1.375rem]"
                >
                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px]">Connected wallet</h1>
                    <h1 className="text-[22px]">{addy}</h1>
                  </div>
                  <div className="flex flex-row items-center w-[100%] text-[#00b3ed] text-[0.875rem] dark:text-[#91e4ff]">
                    <a href="" className="flex flex-row items-center">
                      View on explorer{" "}
                      <img
                        src={
                          theme === "dark"
                            ? "./images/sh.svg"
                            : "./images/sh2.svg"
                        }
                        className="w-[12px] ml-[5px]"
                      />
                    </a>
                    <button
                      className="flex flex-row items-center pl-[20px]"
                      onClick={() => {
                        setConnected(false);
                      }}
                    >
                      Disconnect{" "}
                      <img
                        src={
                          theme === "dark"
                            ? "./images/di.svg"
                            : "./images/di2.svg"
                        }
                        className="w-[12px] ml-[5px]"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px]">Network</h1>
                    <h1 className="text-[22px]">Mainnet</h1>
                  </div>

                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px]">Recent transactions</h1>
                    <h1 className="text-[22px]">No transactions</h1>
                  </div>

                  <div className="flex flex-col items-start w-[100%] pt-[10px]">
                    <h1 className="text-[14px]">Liquidations</h1>
                    <h1 className="text-[22px]">No liquidations</h1>
                  </div>
                </section>
              </section>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Connect
        setConnectWallet={setConnectWallet}
        connectWallet={connectWallet}
        onClose={onClose}
      />
    </body>
  );
};

export default StakeLayout;
