import { Link } from "react-router-dom";

const Overview = ({ theme }) => {
  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-[#f2f1e2] text-center
        outfit dark:bg-[#2a3b49] px-[20px] lg:px-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <img
        src={` ${
          theme === "dark" ? "./images/igLight.svg" : "./images/iglogo.svg"
        }`}
        className="hidden lg:flex w-[240px]"
      />

      <h1 className="pb-[30px] pt-[50px] text-[1.25rem] font-[700] ">
        VALIDATORS. VALIDATORS. VALIDATORS.
      </h1>

      <section className="w-[100%] flex flex-col items-start">
        <div className="flex flex-row items-center justify-between w-[100%] text-[16px] pb-[10px]">
          <h1>Ignite capacity</h1>
          <h1 className="flex flex-row items-center">
            300k <img src="./images/avax.svg" className="w-[32px] ml-[10px]" />
          </h1>
        </div>
        <section className="dark:bg-[#3a5163] w-[100%] h-[48px] rounded-[9999px] bg-white">
          <div className="o"></div>
        </section>
        <h1 className="text-[#ff5b22] font-[700] text-[1.125rem] pt-[10px]">
          2000 AVAX
        </h1>
      </section>

      <h1 className="w-[100%] text-center text-[1.125rem] font-[400] py-[40px]">
        BENQI Ignite helps builders, developers, and users to effortlessly
        deploy Avalanche validators in just a few minutes, and at a fraction of
        the cost.
      </h1>

      <section
        className="h-[204px] flex flex-col items-center justify-center py-[20px] w-[100%] rounded-lg dark:bg-[#3a5163] 
      bg-white"
      >
        <h1 className="text-[1.5rem] font-[500] pb-[30px]">
          Ignite Statistics
        </h1>

        <section className="flex flex-row items-center w-[100%] justify-between">
          <div className="flex flex-col items-center justify-center w-[50%] border-solid border-r-[1px] dark:border-[#4e6373] border-[#f7f7f7]">
            <h1 className="dark:text-white text-[#7b8e9d] text-[.875rem] font-[500]">
              Validators
            </h1>
            <h1 className="text-[1.5rem] font-[700]">1</h1>
          </div>
          <div className="flex flex-col items-center justify-center w-[50%]">
            <h1 className="dark:text-white text-[#7b8e9d] text-[.875rem] font-[500]">
              Total AVAX
            </h1>
            <h1 className="text-[1.5rem] font-[700]">2000</h1>
          </div>
        </section>
      </section>

      <Link
        to="./validators"
        className="bg-[#ff5b22] text-[#001f29] text-[16px] font-[500] w-[166px]
        mt-[30px] rounded-[.375rem] h-[52px] flex flex-row items-center justify-center"
      >
        Get started
      </Link>
    </body>
  );
};

export default Overview;
