import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { pages, morePages } from "../maps";

const StakeSide = ({
  addy,
  setTheme,
  theme,
  setShowSide,
  connectWallet,
  setConnectWallet,
  connected,
  setBreakdown,
  setShowDetails,
  setConnected,
}) => {
  const handleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      document.documentElement.classList.remove("dark");
    } else {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    }
  };
  const location = useLocation();

  return (
    <body
      className="dark:bg-[#002430] bg-[#d7edfd] min-h-[100vh] lg:p-[20px]
        flex flex-col items-start lg:items-center outfit w-[100%] overflow-y-scroll"
    >
      {!connected && (
        <div
          className="lg:hidden flex flex-row items-center justify-end w-[100%] pb-[30px]
                cursor-pointer"
        ></div>
      )}
      <a href="/" className="flex flex-row items-center w-[100%]">

      <img
        src={`${theme === "dark" ? "./images/darkBq.svg" : "./images/bq.svg"}`}
        className="hidden lg:flex"
      />
      </a>
      {connected && (
        <section
          className="flex flex-col p-[20px] items-start w-[100%] dark:bg-[#08799e] bg-[#e8f5ff]
 text-[#434f5b] dark:text-white lg:hidden"
        >
          <div className="flex flex-row items-end w-[100%] justify-end">
            <FontAwesomeIcon
              icon={faX}
              className="dark:text-white text-black lg:hidden flex text-[16px] cursor-pointer"
              onClick={() => {
                setShowSide(false);
              }}
            />
          </div>

          <div className="flex flex-col items-start w-[100%]">
            <h1 className="text-[0.875rem]">Connect wallet</h1>
            <h1 className="text-[1.688rem]">{addy}</h1>
          </div>

          <section className="flex flex-row items-center w-[100%] pt-[10px]">
            <button
              className="flex flex-row items-center text-[12px]"
              onClick={() => {
                setShowDetails(true);
              }}
            >
              <i class="bx bxs-info-circle pr-[5px]"></i>
              <h1>Details</h1>
            </button>

            <div className="flex flex-row items-center text-[12px] px-[10px]">
              <i class="bx bxs-bell pr-[5px]"></i>
              <h1>Notifications</h1>
            </div>

            <button
              className="flex flex-row items-center text-[12px]"
              onClick={() => {
                setConnected(false);
              }}
            >
              <img
                src={theme === "dark" ? "./images/pp.svg" : "./images/pp2.svg"}
                className="w-[10px] mr-[5px]"
              />
              <h1>Disconnected</h1>
            </button>
          </section>
        </section>
      )}
      <div
        className={` ${
          connected
            ? "hidden lg:flex"
            : "lg:flex flex-row items-center flex px-[20px]"
        }  items-center justify-between w-[100%] lg:mt-[60px]`}
      >
        <button
          className="lg:bg-white lg:dark:bg-[#000000] border-solid border-[2px] border-[#00b3ed] text-black lg:dark:text-white
         text-[16px] font-[500] w-[148px] lg:w-[100%] lg:hover:bg-[#00b3ed] bg-[#00b3ed]
        rounded-[0.1875rem] py-[5px] flex flex-row items-center justify-center hover:dark:text-white"
          onClick={() => {
            connected ? setShowDetails(true) : setConnectWallet(true);
          }}
        >
          {connected ? addy : "Connect Wallet"}
        </button>

        {connected && (
          <button
            className=" lg:dark:bg-[#000000] border-solid border-[2px] border-[#00b3ed] text-black lg:dark:text-white
         text-[16px] font-[500] w-[36px] h-[36px] ml-[10px] lg:hover:bg-[#00b3ed] bg-transparent
        rounded-[0.1875rem] py-[5px] flex flex-row items-center justify-center hover:dark:text-white"
            onClick={() => {
              setConnectWallet(true);
            }}
          >
            <i class="bx bxs-bell"></i>
          </button>
        )}

        <FontAwesomeIcon
          icon={faX}
          className="dark:text-white text-black lg:hidden flex text-[25px] cursor-pointer"
          onClick={() => {
            setShowSide(false);
          }}
        />
      </div>

      <section className="flex flex-col items-start w-[100%] lg:px-[0px] px-[20px]">
        <section
          className={`flex flex-col  items-center w-[100%] border-solid ${
            connected ? "border-b-[1px]" : "border-y-[1px]"
          }  py-[30px] border-[#00b3ed] my-[20px]`}
        >
          {pages.map((us, id) => (
            <Link
              key={id}
              className={`flex flex-row items-center justify-start px-[20px] text-[15px] lg:justify-center w-[100%] ${
                us.id !== 4 && "mb-[15px]"
              } h-[36px] rounded-[.125rem]
                    ${
                      location.pathname === us.link
                        ? "bg-[#00b3ed] text-white dark:text-[#001f29]"
                        : "lg:dark:bg-[#003f54] text-[#001f29] lg:bg-white dark:text-white lg:hover:border-[2px] border-solid border-[#00b3ed]"
                    } `}
              to={us.link}
            >
              {us.title}
            </Link>
          ))}
        </section>

        <div
          className="flex flex-row items-center justify-center dark:text-white text-[#001f29] text-[16px] w-[100%]
      
      "
        >
          <i class="bx bx-moon"></i>
          <span className="switch px-[5px]">
            <input id="switch-rounded" type="checkbox" />
            <label
              for="switch-rounded"
              className={`${
                theme === "dark"
                  ? "bg-[#161616] hover:bg-[#91e4ff]"
                  : "bg-[#90cdf4] hd"
              } flex flex-row items-center border-solid border-[1px] border-[#001f29]`}
              onClick={() => {
                handleTheme();
              }}
            ></label>
          </span>
          <i class="bx bx-sun"></i>
        </div>

        <div className="flex flex-col items-start w-[100%] pb-[30px] border-solid border-b-[1px] my-[20px] border-[#00b3ed]">
          {morePages.map((us, id) => (
            <a
              href={us.link}
              key={id}
              className="flex flex-row items-center text-[15px] font-[400] mb-[15px] text-black dark:text-white"
            >
              <img
                src={theme === "dark" ? us.icon : us.darkIcon}
                className="w-[28px]"
              />
              <h1 className="pl-[10px] ">{us.name}</h1>
            </a>
          ))}
        </div>

        <div className="flex flex-col items-start justify-start text-left lg:justify-center w-[100%] text-[#707070] text-[11px] font-[500]">
          <h1 className="lg:pt-[20px]">
            This project is in beta. <br className="lg:flex hidden" /> Use at
            your own risk.
          </h1>
        </div>
      </section>
    </body>
  );
};

export default StakeSide;
