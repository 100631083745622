import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import {
  Layout,
  IgLayout,
  StakeLayout,
  LendLayout,
  Bridge,
  Overview,
  Validators,
  Defi,
  Vote,
  Staking,
  Rewards,
  Market,
  LendOverview,
} from "./Components";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";

function App() {
  const [theme, setTheme] = useState("dark");
  const { onClose } = useDisclosure();
  const [connectWallet, setConnectWallet] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [connected,setConnected] = useState(false);
  const [breakdown, setBreakdown] = useState(false);
  const [showDetails, setShowDetails] = useState(false)

  const addy = "89d7g8239vhqe";


  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}></Route>
        <Route
          element={
            <StakeLayout
              theme={theme}
              setTheme={setTheme}
              connectWallet={connectWallet}
              setConnectWallet={setConnectWallet}
              onClose={onClose}
              showSide={showSide}
              setShowSide={setShowSide}
              connected={connected}
              addy={addy}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              setConnected={setConnected}
              
            />
          }
          path="/stake"
        >
          <Route
            element={
              <Defi
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                connected={connected}
              />
            }
            path="/stake/defi"
          />
          <Route
            element={
              <Vote
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                connected={connected}
              />
            }
            path="/stake/vote"
          />
          <Route
            element={
              <Staking
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                setShowSide={setShowSide}
                connected={connected}
                showDetails={showDetails}
              setShowDetails={setShowDetails}
              setConnected={setConnected}
              />
            }
            path="/stake"
          />
        </Route>
        <Route
          path="/ig"
          element={<IgLayout theme={theme} setTheme={setTheme} connected={connected} showSide={showSide} setShowSide={setShowSide} />}
        >
          <Route
            path="/ig"
            element={<Overview theme={theme} setTheme={setTheme} connected={connected} showSide={showSide} setShowSide={setShowSide}  />}
          />
          <Route
            path="/ig/validators"
            element={<Validators theme={theme} setTheme={setTheme} connected={connected} showSide={showSide} setShowSide={setShowSide}  />}
          />
        </Route>

        <Route
          element={
            <LendLayout
              theme={theme}
              setTheme={setTheme}
              connectWallet={connectWallet}
              setConnectWallet={setConnectWallet}
              onClose={onClose}
              showSide={showSide}
              setShowSide={setShowSide}
              connected={connected}
              addy={addy}
              breakdown={breakdown}
              setBreakdown={setBreakdown}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              setConnected={setConnected}
            />
          }
          path="/lend"
        >
          <Route
            element={
              <Bridge
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={connected}
              />
            }
            path="/lend/bridge"
          />
          <Route
            element={
              <Rewards
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={connected}
              />
            }
            path="/lend/rewards"
          />
          <Route
            element={
              <Market
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={connected}
                breakdown={breakdown}
                setBreakdown={setBreakdown}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
              />
            }
            path="/lend"
          />
          <Route
            element={
              <LendOverview
                theme={theme}
                setTheme={setTheme}
                connectWallet={connectWallet}
                setConnectWallet={setConnectWallet}
                onClose={onClose}
                showSide={showSide}
                connected={connected}
              />
            }
            path="/lend/overview"
          />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
