const sort = [
    {
      id: 1,
      name: "Asset",
    },
    {
      id: 2,
      name: "Total supply APY",
    },
    {
      id: 3,
      name: "Total supply",
    },
    {
      id: 4,
      name: "Total borrow APY",
    },
    {
      id: 5,
      name: "Total borrow",
    },
    {
      id: 5,
      name: "Total liquidity",
    },
  ];

  export default sort