const pages = [
    {
      id: 1,
      title: "Liquid Staking",
      link: "/stake",
    },
    {
      id: 2,
      title: "Vote with veQI",
      link: "/stake/vote",
    },
  ];

  export default pages