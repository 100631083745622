const ty = [
    {
      id: 1,
      name: "ascending",
    },
    {
      id: 2,
      name: "descending",
    },
  ];

  export default ty