import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const IgSide = ({
  setTheme,
  theme,
  setShowSide,
  setConnectWallet,
  connected,
}) => {
  const handleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
      document.documentElement.classList.remove("dark");
    } else {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    }
  };
  const location = useLocation();

  const pages = [
    {
      id: 1,
      title: "Overview",
      link: "/ig",
      name: "ig",
    },
    {
      id: 2,
      title: "My validators",
      link: "/ig/validators",
      name: "validator",
    },
    {
      id: 3,
      title: "Liquid Staking",
      link: "/stake",
    },
    {
      id: 4,
      title: "Lend and borrow",
      link: "/lend",
    },
  ];

  const add = [
    {
      id: 1,
      title: "Add QI",
      icon: "./images/add1.svg",
    },
    {
      id: 2,
      title: "Add sAVAX",
      icon: "./images/add2.svg",
    },
  ];

  return (
    <body
      className="dark:bg-[#3a5163] bg-white p-[20px] min-h-[100vh]
        flex flex-col items-start lg:items-center outfit lg:pt-[50px] w-[100%] overflow-y-scroll"
    >
      <div
        className="lg:hidden flex flex-row items-center justify-end w-[100%] pb-[30px]
                cursor-pointer"
      >
        <FontAwesomeIcon
          icon={faX}
          className="text-black"
          onClick={() => {
            setShowSide(false);
          }}
        />
      </div>
      <img
        src={` ${
          theme === "dark" ? "./images/igLight.svg" : "./images/iglogo.svg"
        }`}
        className="w-[120px] lg:block hidden"
      />

      <button
        className="bg-[#ff5b22] text-white dark:text-[#001f29] text-[16px] font-[500] w-[148px] lg:w-[100%]
        mt-[30px] rounded-[.375rem] py-[10px] flex flex-row items-center justify-center hover:dark:text-white"
        onClick={() => {
          setConnectWallet(true);
        }}
      >
        {connected
          ? "brtedcscwertg53wrevfgbrynwregrfawdcvsg"
          : "Connect Wallet"}
      </button>

      <section className="flex flex-col items-center w-[100%] border-solid border-y-[1px] dark:border-white py-[30px] border-[#cdcdcd] my-[20px]">
        {pages.map((us, id) => (
          <Link
            key={id}
            className={`flex flex-row items-center justify-start px-[20px] lg:justify-center w-[100%] ${
              us.id !== 4 && "mb-[15px]"
            } h-[36px] rounded-[.125rem]
                    ${
                      location.pathname === us.link
                        ? "bg-[#403f3b] text-white dark:bg-white dark:text-[#001f29]"
                        : "lg:bg-[#d6d5c7] hover:bg-[#403f3b] hover:text-white dark:text-white text-[#001f29] dark:lg:bg-[#23323d] dark:text-white dark:hover:bg-white dark:hover:text-[#001f29]"
                    } `}
            to={us.link}
          >
            {us.title}
          </Link>
        ))}
      </section>

      <div
        className="flex flex-row items-center justify-center dark:text-white text-[#001f29] text-[16px] w-[100%]
      
      "
      >
        <i class="bx bx-moon"></i>
        <span className="switch px-[5px]">
          <input id="switch-rounded" type="checkbox" />
          <label
            for="switch-rounded"
            className={`${
              theme === "dark"
                ? "bg-[#161616] hover:bg-[#91e4ff]"
                : "bg-[#90cdf4] hd"
            } flex flex-row items-center border-solid border-[1px] border-[#001f29]`}
            onClick={() => {
              handleTheme();
            }}
          ></label>
        </span>
        <i class="bx bx-sun"></i>
      </div>

      <div className="flex flex-col items-start w-[100%] pb-[30px] border-solid border-b-[1px] dark:border-white my-[20px] border-[#cdcdcd]">
        {add.map((us, id) => (
          <button
            key={id}
            className="flex flex-row items-center text-[16px] font-[500] mb-[10px] text-[#768692]"
          >
            <img src={us.icon} />
            <h1 className="pl-[10px]">{us.title}</h1>
          </button>
        ))}
      </div>

      <div className="flex flex-col items-center justify-center w-[100%] text-[#768692] text-[16px] font-[500]">
        <img
          src={`${
            theme === "dark"
              ? "./images/darkIgBenqi.svg"
              : "./images/igBenqi.svg"
          }`}
        />
        <h1 className="pt-[20px]">This project is in beta.</h1>
        <h>Use at your own risk.</h>
      </div>
    </body>
  );
};

export default IgSide;
