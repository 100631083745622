import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const Footer = () => {
    const det = [
        {
            id: 1,
            title: "Liquid Staking",
            body: "Stake AVAX and earn. Seamlessly use it within DeFi.",
            t2: "Total staked",
            b2: "$286,003,117.21",
            linkTitle: "Stake now",
            link: "/#/stake",
            bg: "https://benqi.fi/images/savax-blue.svg",
            w: ""
        },
        {
            id: 2,
            title: "Markets",
            body: "Lend, borrow and earn with crypto.",
            t2: "Total supplied",
            b2: "$298,480,856.17",
            linkTitle: "Lend and borrow",
            link: "/#/lend",
            bg: "https://benqi.fi/images/tokens.svg",
            w: ""
        },
        {
            id: 3,
            title: "Ignite",
            body: "Run your own Avalanche validator or subnet",
            t2: "",
            b2: "",
            linkTitle: "Start now",
            link: "/#/ig",
            bg: "https://benqi.fi/images/ignite-blue.svg",
            w: ""
        },
    ];

    const ft = [
        {
            id: 1,
            title: "Analytics",
            t1: "Chaos Labs",
            t2: "InfoTheBlock",
            t3: "DefiLlama",
            t4: "Token Terminal",
            t5: "Risk DAO",
            t1Link: "https://community.chaoslabs.xyz/benqi",
            t2Link: "https://defirisk.intotheblock.com/metrics/avalanche/benqi",
            t3Link: "https://defillama.com/protocol/benqi",
            t4Link: "https://tokenterminal.com/terminal/projects/benqi",
            t5Link: "https://bad-debt.riskdao.org/",
        },
        {
            id: 2,
            title: "Resources",
            t1: "Docs",
            t2: "GitHub",
            t3: "Medium",
            t4: "YouTube",
            t5: "Media Kit",
            t6: "Audits",
            t1Link: "https://docs.benqi.fi/",
            t2Link: "https://github.com/Benqi-fi",
            t3Link: "https://benqifinance.medium.com/",
            t4Link: "https://www.youtube.com/c/benqifinance",
            t5Link: "https://docs.benqi.fi/resources/press-kit-logos-and-graphics",
            t6Link: "https://docs.benqi.fi/resources/risks#audits",
        },
    ]
    return(
        <footer className="downBg min-h-[100vh] out flex flex-col items-center justify-center py-[50px] px-[50px]">

            <section className="flex flex-col flex-col items-center w-[100%] justify-center
          pb-[150px] px-[50px]">
             <section className="lg:w-[80%] flex flex-col lg:flex-row items-start justify-center">

                {
                    det.map((us, id) => (
                        <div key={id} className="pt-[3rem] pl-[2.75rem] bg-[#022a36] h-[380px]
                        w-[100%] lg:w-[30%] my-[20px] lg:mx-[20px] rounded-[1.5rem] flex flex-col">
                        <div className="flex flex-col items-start pr-[1rem] text-white z-30 justify-between h-[360px]">

                            <h1 className="text-[30px] font-[700]">{us.title}</h1>
                            <h2 className="text-[1.25rem] font-[300]">{us.body}</h2>

                            <h2 className="text-[17px] font-[300]">{us.t2}</h2>
                            <h2 className="font-[700] text-[1.5rem]">{us.b2}</h2>

                            <a target="_blank" href={us.link} className="text-[#11baf0] text-[17px] font-[300] flex flex-row items-end">
                            {us.linkTitle}
                            <span className="text-[17px] pl-[5px] pt-[5px]">→</span>
                            </a>
                        </div>

                        <div className="w-[100%] flex flex-row items-end justify-end pt-[50px]">

                        <img 
                            src={us.bg}
                            className={`bri w-[150px] absolute z-20 ${us.id === 1 && "w-[240px]"}
                            ${us.id === 2 && "w-[180px]"} ${us.id === 3 && "w-[150px]"}
                            `}
                            
                        />
                        </div>
                        </div>
                    ))
                }
             </section>
            </section>

            <section className="flex flex-col lg:flex-row items-start justify-between lg:w-[80%]
            border-solid border-t-[2px] w-[100%] lg:border-y-[2px] border-[#11baf0] py-[60px] text-white ">

                <div className="flex flex-col items-center justify-center text-center w-[100%] lg:w-[25%]"> 
                    <img src="https://benqi.fi/images/logo.svg" className="w-[240px]" />
                    <h1 className="pt-[20px] text-[16px] font-[400]">In collaboration with</h1>
                    <a className="font-[600] text-[16px]" href="https://romeblockchain.com/home">Rome Blockchain Labs</a>

                    <h1 className="text-[14px] pt-[20px] font-[400] opacity-[.6] hidden lg:flex">Copyright ©️ 2021 - 2023 BENQI</h1>
                    <h1 className="text-[14px] font-[400] opacity-[.6] hidden lg:flex">All Rights Reserved</h1>
                </div>

                <section className="flex flex-row items-start justify-between w-[100%] lg:w-[30%] pt-[30px] pb-[20px]
                lg:pt-[0px] lg:pb-[0px]">

                {
                    ft.map((us, id ) => (
                        <div key={id} className="flex flex-col items-start ">
                        <h1 className="text-[1.5rem] font-[400] pb-[20px]">{us.title}</h1>

                        <a href={us.t1Link} className="text-[16px] pb-[5px]">{us.t1}</a>
                        <a href={us.t2Link} className="text-[16px] pb-[5px]">{us.t2}</a>
                        <a href={us.t3Link} className="text-[16px] pb-[5px]">{us.t3}</a>
                        <a href={us.t4Link} className="text-[16px] pb-[5px]">{us.t4}</a>
                        <a href={us.t5Link} className="text-[16px] pb-[5px]">{us.t5}</a>
                        <a href={us?.t6Link} className="text-[16px] ">{us?.t6}</a>
                        </div>
                     ))
                }
                </section>
                
                <div className="flex flex-col items-start">
                        <h1 className="text-[1.5rem] font-[400] pb-[20px]">Connect</h1>

                        <a href="https://twitter.com/BenqiFinance" className="text-[16px] pb-[5px]">Twitter</a>
                        <a href="https://t.co/WkmdNK4Q0g" className="text-[16px] pb-[5px]">Discord</a>
                        <h1 className="text-[16px] pb-[5px]">Telegram</h1>
                        <a href="https://t.me/BENQIFinance" className="text-[16px] pb-[5px] pl-[15px]">Discussions (English)</a>
                        <a href="https://t.me/BENQI_zh" className="text-[16px] pb-[5px] pl-[15px]">Discussions (Chinese)</a>
                        <a href="https://t.me/benqifinance_announcements" className="text-[16px]  pl-[15px]">Announcements</a>
                        </div>
                    <div>

                        <h1 className="text-[14px] pt-[20px] font-[400] opacity-[.6] lg:hidden flex text-center">Copyright ©️ 2021 - 2023 BENQI, All Rights Reserved </h1>
                    </div>

            </section>

            <section className="hidden lg:flex flex-col items-start lg:w-[80%] pt-[20px] text-white">
                <h1>LEGAL DISCLAIMER</h1>
                <h2 className="text-[#d1d5db] text-[14px]">The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the website's content as such. The BENQI team provides the website as a service to the public, and is not responsible for, and expressly disclaims all liability for, damages of any kind arising out of use, reference to, or reliance on any information contained within this website. While the information contained within this website is periodically updated, no guarantee is given that the information provided in this website is correct, complete, and up-to-date.</h2>
            </section>
        </footer>
    )
}

export default Footer