import { useState } from "react";
import { ov, sort, ty } from "../../maps";
import P2 from "./P2";

const LendOverview = ({
  connectWallet,
  showSide,
  connected,
}) => {
  const [page, setPage] = useState(1);
  const [p, setP] = useState(1);
  const [mode, setMode] = useState(1);
  const [sortValue, setSortValue] = useState("Asset");
  const [order, setOrder] = useState("ascending");
  const [t, setT] = useState(false);
  const [v, setV] = useState(false);
  const [current, setCurrent] = useState({
    name: "AVAX",
    icon: "https://app.benqi.fi/svgs/tokens/avax.svg",
    value: "30.00",
    apy: "2.03",
    supply: "1.36M",
    vol: "40.86M",
    apy2: "-2.55",
    borrow: "717.85k",
    borrowVol: "21.56M",
    liq: "600k",
    liqVol: "19.31M",
    distributionApy: "0.21",
    distributionApy2: "1.82",
    totalSupply: "1,358,940.04",
    totalBorrow: "717,959.72",
    supplier: "5",
    borrowers: "5",
    reserve: "5",
    collateral: "5",
    borrowCap: "1,900,000",
    totalLiq: "640,670",
    totalLiqVol: "5",
    hSupply: "1.00",
    hborrow: "9.00 ",
    hborrowTotal: "269.85",
    supplyQi: "202.54",
    supplyVal: "5",
    borrowQi: "5",
    totalb: "5",
    borrowQiTotal: "810.18",
    minted: "61,649,073",
    rate: "5",
  });


  const setChange = (e) => {
    setCurrent({
      name: e.name,
      icon: e.icon,
      value: e.value,
      apy: e.apy,
      supply: e.supply,
      vol: e.vol,
      apy2: e.apy2,
      borrow: e.borrow,
      borrowVol: e.borrowVol,
      liq: e.liq,
      liqVol: e.liqVol,
      distributionApy: e.distributionApy,
      distributionApy2: e.distributionApy2,
      totalSupply: e.totalSupply,
      totalBorrow: e.totalBorrow,
      supplier: e.supplier,
      borrowers: e.borrowers,
      reserve: e.reserve,
      collateral: e.collateral,
      borrowCap: e.borrowCap,
      totalLiq: e.totalLiq,
      totalLiqVol: e.totalLiqVol,
      hSupply: e.hSupply,
      hborrow: e.hborrow,
      hborrowTotal: e.hborrowTotal,
      supplyQi: e.supplyQi,
      supplyVal: e.supplyVal,
      borrowQi: e.borrowQi,
      totalb: e.totalb,
      borrowQiTotal: e.borrowQiTotal,
      minted: e.minted,
      rate: e.rate,
    });
  };

  let grid = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="table"
      class="svg-inline--fa fa-table fa-w-16 "
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
      ></path>
    </svg>
  );
  let line = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="stream"
      class="svg-inline--fa fa-stream fa-w-16 "
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M16 128h416c8.84 0 16-7.16 16-16V48c0-8.84-7.16-16-16-16H16C7.16 32 0 39.16 0 48v64c0 8.84 7.16 16 16 16zm480 80H80c-8.84 0-16 7.16-16 16v64c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-64c0-8.84-7.16-16-16-16zm-64 176H16c-8.84 0-16 7.16-16 16v64c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-64c0-8.84-7.16-16-16-16z"
      ></path>
    </svg>
  );

  const [ac, setAc] = useState(1);

  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
          outfit dark:bg-[#003f54] px-[20px] lg:px-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      {page === 1 && (
        <section className="lg:w-[900px] w-[100%] flex flex-col items-start rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] px-[20px]">
          <h1 className="text-[30px] text-black dark:text-white font-[300] pb-[40px]">
            Overview
          </h1>

          <section className="flex flex-col lg:flex-row items-start w-[100%] justify-between">
            <div
              className={`${
                connected && "lg:h-[138px]"
              } dark:bg-[#001820] bg-white w-[100%] lg:w-[49%] rounded-[.375rem] p-[1.25rem] flex flex-col items-start text-left`}
            >
              <h1 className="text-[15px] font-[300] text-[#00b3ed]">
                Total supply
              </h1>
              <h1 className="text-[#00b3ed] text-[27px] font-[400]">
                $243,339,363.45
              </h1>
            </div>

            <div
              className={` ${
                connected && "lg:h-[138px]"
              } dark:bg-[#001820] bg-white w-[100%] lg:w-[49%] mt-[20px] lg:ml-[10px] lg:mt-[0px] 
            rounded-[.375rem] p-[1.25rem] flex flex-col items-start text-left`}
            >
              <h1 className="text-[15px] font-[300] text-[#f5bc7c]">
                Total borrow
              </h1>
              <h1 className="text-[#f5bc7c] text-[27px] font-[400]">
                $59,690,678.61
              </h1>
            </div>

            {connected && (
              <div className="dark:bg-[#001820] bg-white w-[100%] lg:ml-[10px] lg:w-[49%] mt-[20px] lg:mt-[0px] rounded-[.375rem] p-[1.25rem] flex flex-col items-start text-left">
                <h1 className="text-[15px] font-[300] text-[#f5bc7c]">
                  Your borrow limit
                </h1>
                <h1 className="text-[#f5bc7c] text-[27px] font-[400]">
                  0.00 %
                </h1>
                <div className="w-[100%] rounded-[.125rem] h-[4px] dark:bg-[#434f5b] my-[5px]"></div>

                <section className="flex flex-row items-center w-[100%] justify-between text-[14px]">
                  <button className="w-[50%] flex flex-row items-center font-[300]">
                    <div className="bg-[#2ecc40] w-[10px] h-[10px] rounded-[50%] mr-[5px]"></div>{" "}
                    Health: 100.00{" "}
                  </button>

                  <button className="w-[50%] flex flex-row items-center justify-end font-[300]">
                    Remaining: $0.00
                  </button>
                </section>
              </div>
            )}
          </section>

          {connected && (
            <section className="flex flex-col lg:flex-row items-start justify-between w-[100%] pt-[20px]">
              <div className="lg:w-[49%] w-[100%] flex flex-col items-start ">
                <h1 className="dark:opacity-[.75] text-[0.875rem] font-[300] pb-[10px]">
                  Supplied
                </h1>

                <div
                  className="w-[100%] flex flex-row items-center justify-center rounded-[] dark:bg-[#001016]
            rounded-[.375rem] p-[.75rem]"
                >
                  No supplied assets
                </div>
              </div>
              <div className="lg:w-[49%] w-[100%] flex flex-col items-start ">
                <h1 className="dark:opacity-[.75] text-[0.875rem] font-[300] pb-[10px]">
                  Borrowed
                </h1>

                <div
                  className="w-[100%] flex flex-row items-center justify-center rounded-[] dark:bg-[#001016]
            rounded-[.375rem] p-[.75rem]"
                >
                  No borrowed assets
                </div>
              </div>
            </section>
          )}

          <section className="flex flex-col items-start w-[100%] lg:flex hidden">
            <section className="flex flex-row items-end w-[100%] pt-[30px]  ">
              <div className="w-[20%] pl-[15px]">
                <h1 className="text-left pb-[20px] text-[14px]">
                  Asset <i class="bx bxs-up-arrow text-[10px]"></i>
                </h1>
              </div>

              <section className="w-[80%] flex flex-row items-start dark:bg-[#003f54] bg-white rounded-t-[.375rem] p-[15px] justify-between">
                <div className="flex flex-col items-center justify-center w-[30%]">
                  <h1 className="text-[#00b3ed] text-[18px] font-[400] pb-[20px]">
                    Supply
                  </h1>
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1 className="text-[14px] text-[#00b3ed] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                      </div>
                      Total APY
                    </h1>
                    <h1 className="text-[14px] text-[#00b3ed] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                      </div>
                      Total supply
                    </h1>
                  </div>
                </div>

                <div className="flex flex-col items-center justify-center w-[60%]">
                  <h1 className="text-[#f5bc7c] text-[18px] font-[400] pb-[20px]">
                    Borrow
                  </h1>
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1 className="text-[14px] text-[#f5bc7c] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>{" "}
                      </div>
                      Total APY
                    </h1>
                    <h1 className="text-[14px] text-[#f5bc7c] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>{" "}
                      </div>
                      Total borrow
                    </h1>
                    <h1 className="text-[14px] text-[#f5bc7c] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>{" "}
                      </div>
                      Total liquidity
                    </h1>
                  </div>
                </div>
              </section>
            </section>

            <section className="flex flex-col items-start w-[100%]">
              {ov.map((us, id) => (
                <div
                  key={id}
                  className="flex flex-row items-center mb-[10px] justify-between w-[100%] cursor-pointer can rounded-[.375rem]"
                  onClick={() => {
                    setPage(2);
                    setChange(us);
                  }}
                >
                  <section className="w-[20%] h-[56px] flex flex-row items-center bg-[#bae8fc] dark:bg-[#001218] p-[15px] rounded-l-[.375rem]">
                    <img src={us.icon} className="w-[32px]" />
                    <div className="w-[90%] flex flex-col items-start pl-[20px]">
                      <h1 className="text-[14px] font-[400]">{us.name}</h1>
                      <h1 className="text-[14px] font-[300] dark:text-[#b9b9b9] flex flex-row items-center">
                        $ {us.value}
                      </h1>
                    </div>
                  </section>

                  <section
                    className="w-[80%] flex flex-row items-center justify-between rounded-r-[.375rem] dark:bg-[#001f2a]
              p-[15px] h-[56px] bg-[#ccf0fc]"
                  >
                    <section className="w-[30%] flex flex-row items-start justify-between">
                      <h1 className="text-[14px] font-[400]">{us.apy} %</h1>
                      <div className="flex flex-col items-end ">
                        <h1 className="text-[14px] font-[400]">
                          {us.supply} {us.name}
                        </h1>
                        <h1 className="text-[14px] font-[300] dark:text-[#b9b9b9]">
                          $ {us.vol}
                        </h1>
                      </div>
                    </section>
                    <section className="w-[60%] flex flex-row items-start justify-between">
                      <h1 className="text-[14px] font-[400]">{us.apy2} %</h1>
                      <div className="flex flex-col items-end ">
                        <h1 className="text-[14px] font-[400]">
                          {us.borrow} {us.name}
                        </h1>
                        <h1 className="text-[14px] font-[300] dark:text-[#b9b9b9]">
                          $ {us.borrowVol}
                        </h1>
                      </div>
                      <div className="flex flex-col items-end ">
                        <h1 className="text-[14px] font-[400]">
                          {us.liq} {us.name}
                        </h1>
                        <h1 className="text-[14px] font-[300] dark:text-[#b9b9b9]">
                          $ {us.liqVol}
                        </h1>
                      </div>
                    </section>
                  </section>
                </div>
              ))}
            </section>

            <h1 className="dark:opacity-[.75] text-[15px] font-[300] text-left pt-[10px]">
              Click on a token to view analytics.
            </h1>
          </section>

          <section className="lg:hidden flex flex-col items-start w-[100%]">
            <section className="flex flex-col items-start justify-start w-[100%] pt-[20px]">
              <h1 className="pb-[10px] text-[#202329] text-[16px] font-[300] dark:text-[#d2d2d2]">
                Sort By
              </h1>

              <div className="flex flex-row items-center justify-between w-[100%] ">
                <button
                  className="flex flex-row items-center justify-between w-[49%]
        border-solid border-[1px] border-[#5ed8ff] dark:border-[#707070] rounded-[.375rem] h-[40px] px-[10px]"
                  onClick={() => {
                    setT(!t);
                    setV(false);
                  }}
                >
                  {sortValue} <i class="bx bx-chevron-down"></i>
                </button>
                <button
                  className="flex flex-row items-center justify-between w-[49%]
        border-solid border-[1px] border-[#5ed8ff] dark:border-[#707070] rounded-[.375rem] h-[40px] px-[10px]"
                  onClick={() => {
                    setV(!v);
                    setT(false);
                  }}
                >
                  {order} <i class="bx bx-chevron-down"></i>
                </button>
              </div>

              <section className="flex flex-row items-start w-[100%] justify-between">
                {v && <div className="w-[49%]"></div>}
                {t && (
                  <div className="flex flex-col items-start w-[49%] dark:bg-black bg-white rounded-b-[.375rem] py-[5px]">
                    {sort.map((us, id) => (
                      <div
                        key={id}
                        className={`hover:dark:bg-[#3e444e] hover:bg-[#d2d2d2] 
              w-[100%] flex flex-row p-[5px] cursor-pointer ${
                sortValue === us.name && "hidden"
              } `}
                        onClick={() => {
                          setSortValue(us.name);
                        }}
                      >
                        {us.name}
                      </div>
                    ))}
                  </div>
                )}
                {v && (
                  <div className="flex flex-col items-start  w-[49%] dark:bg-black bg-white rounded-b-[.375rem] py-[5px]">
                    {ty.map((us, id) => (
                      <div
                        key={id}
                        className={`hover:dark:bg-[#3e444e] hover:bg-[#d2d2d2] 
              w-[100%] flex flex-row p-[5px] cursor-pointer ${
                order === us.name && "hidden"
              } `}
                        onClick={() => {
                          setOrder(us.name);
                        }}
                      >
                        {us.name}
                      </div>
                    ))}
                  </div>
                )}
                {t && <div className="w-[49%]"></div>}
              </section>
            </section>

            <section className="flex flex-col items-start w-[100%] pt-[10px]">
              <h1 className="pb-[10px] text-[#202329] text-[16px] font-[300] dark:text-[#d2d2d2]">
                View Mode
              </h1>

              <section className="flex flex-row items-start w-[100%]">
                <button
                  className="flex flex-row items-center justify-center w-[44px] h-[37px] mr-[10px]
        border-solid border-[1px] border-[#5ed8ff] dark:border-[#707070] rounded-[.375rem] h-[40px] px-[10px]"
                  onClick={() => {
                    setMode(1);
                  }}
                >
                  {line}
                </button>

                <button
                  className="flex flex-row items-center justify-center w-[44px] h-[37px] 
        border-solid border-[1px] border-[#5ed8ff] dark:border-[#707070] rounded-[.375rem] h-[40px] px-[10px]"
                  onClick={() => {
                    setMode(2);
                  }}
                >
                  {grid}
                </button>
              </section>
            </section>

            {mode === 1 && (
              <section className="flex flex-col items-start w-[100%] pt-[20px]">
                {ov.map((us, id) => (
                  <section
                    key={id}
                    className="flex flex-col items-end p-[15px] rounded-[.375rem]
              dark:bg-[#001820] bg-white w-[100%] mb-[10px]"
                  >
                    <div className="flex flex-row items-center justify-between w-[100%] pb-[20px]">
                      <h1 className="flex flex-row items-center">
                        <img src={us.icon} className="w-[32px] mr-[10px]" />
                        {us.name}
                      </h1>
                      <h1>${us.value}</h1>
                    </div>

                    <h1
                      className="flex flex-row border-solid border-b-[1px] border-[#172c33]
              text-[#00b3ed] w-[100%] items-start pb-[5px]"
                    >
                      Supply
                    </h1>

                    <div className="flex flex-row items-center justify-between w-[100%] text-[14px] font-[300] pt-[10px]">
                      <h1 className="text-[#00b3ed]">Total APY</h1>
                      <h1>{us.apy} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>Supply APY</h1>
                      <h1>{us.apy} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>Distribution APY</h1>
                      <h1>{us.distributionApy} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>AVAX</h1>
                      <h1>{us.supplyVal} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>QI</h1>
                      <h1>{us.supplyQi} %</h1>
                    </div>

                    <div className="flex flex-row items-start justify-between w-[100%] text-[14px] font-[300] pt-[10px]">
                      <h1 className="text-[#00b3ed]">Total supply</h1>

                      <div className="flex flex-col items-end">
                        <h1>
                          {us.supply} {us.name}
                        </h1>
                        <h1>${us.totalSupply}</h1>
                      </div>
                    </div>

                    <h1
                      className="flex flex-row border-solid border-b-[1px] border-[#172c33]
              text-[#f5bc7c] w-[100%] items-start pb-[5px] pt-[10px]"
                    >
                      Borrow
                    </h1>

                    <div className="flex flex-row items-center justify-between w-[100%] text-[14px] font-[300] pt-[10px]">
                      <h1 className="text-[#f5bc7c]">Total APY</h1>
                      <h1>{us.apy} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>Supply APY</h1>
                      <h1>{us.apy} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>Distribution APY</h1>
                      <h1>{us.distributionApy} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>AVAX</h1>
                      <h1>{us.supplyVal} %</h1>
                    </div>
                    <div className="flex flex-row items-center opacity-[.75] justify-between w-[100%] text-[14px] font-[300] pt-[5px]">
                      <h1>QI</h1>
                      <h1>{us.supplyQi} %</h1>
                    </div>

                    <div className="flex flex-row items-start justify-between w-[100%] text-[14px] font-[300] pt-[10px]">
                      <h1 className="text-[#f5bc7c]">Total borrow</h1>

                      <div className="flex flex-col items-end">
                        <h1>
                          {us.supply} {us.name}
                        </h1>
                        <h1>${us.totalSupply}</h1>
                      </div>
                    </div>

                    <button
                      className="dark:text-white text-black rounded-[.375rem] bg-[#00b3ed] h-[42px] w-[200px] 
              text-[14px] font-[400] mt-[10px]"
                      onClick={() => {
                        setChange(us);
                        setPage(2);
                      }}
                    >
                      Go to {us.name} analytics page
                    </button>
                  </section>
                ))}
              </section>
            )}

            {mode === 2 && (
              <section className="flex flex-row items-center justify-between w-[100%] p-[15px] bg-white dark:bg-[#161616] rounded-[.375rem] my-[20px]">
                <button
                  className={`border-solid ${
                    ac === 1 && "border-[1px]"
                  } border-[#00b3ed] dark:bg-[#002430] bg-transparent text-[16px] rounded-[.375rem] w-[49%] h-[52px]`}
                  onClick={() => {
                    setAc(1);
                  }}
                >
                  Supply
                </button>

                <button
                  className={`border-solid ${
                    ac === 2 && "border-[1px]"
                  } border-[#f5bc7c] dark:bg-[#002430] bg-transparent text-[16px] rounded-[.375rem] w-[49%] h-[52px]`}
                  onClick={() => {
                    setAc(2);
                  }}
                >
                  Borrow
                </button>
              </section>
            )}

            {mode === 2 && (
              <section className="flex flex-row items-center justify-between w-[100%] py-[20px]  ">
                <div className="pl-[10px]">
                  <h1 className="text-left text-[14px]">
                    Asset <i class="bx bxs-up-arrow text-[10px]"></i>
                  </h1>
                </div>

                {ac === 1 ? (
                  <div className="flex flex-row items-center justify-between w-[70%] lg:w-[55%] pr-[10px]">
                    <h1 className="text-[14px] text-[#00b3ed] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                      </div>
                      Total APY
                    </h1>
                    <h1 className="text-[14px] text-[#00b3ed] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#a5e4f8] dark:text-[#006789]"></i>
                      </div>
                      Total supply
                    </h1>
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-between w-[70%] lg:w-[55%] pr-[10px]">
                    <h1 className="text-[14px] text-[#f5bc7c] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>{" "}
                      </div>
                      Total APY
                    </h1>

                    <h1 className="text-[14px] text-[#f5bc7c] flex flex-row items-center">
                      <div className="flex flex-col items-center justify-center pr-[5px]">
                        <i class="bx bxs-up-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>
                        <i class="bx bxs-down-arrow text-[10px] text-[#fbe7d0] dark:text-[#566a62]"></i>{" "}
                      </div>
                      Total borrow
                    </h1>
                  </div>
                )}
              </section>
            )}

            {mode === 2 && (
              <section className="flex flex-col items-start w-[100%]">
                {ov.map((us, id) => (
                  <div
                    key={id}
                    className="flex mb-[10px] flex-row items-center justify-between w-[100%] cursor-pointer dark:bg-[#001218] can rounded-[.375rem]
              bg-[#bae8fc]"
                    onClick={() => {
                      setPage(2);
                      setChange(us);
                    }}
                  >
                    <section className=" lg:w-[20%] h-[56px] flex flex-row items-center  p-[15px] rounded-l-[.375rem]">
                      <img src={us.icon} className="w-[32px]" />
                      <div className="w-[100%] flex flex-col items-start pl-[10px] lg:pl-[20px]">
                        <h1 className="text-[14px] font-[400]">{us.name}</h1>
                        <h1 className="text-[14px] font-[300] dark:text-[#b9b9b9] flex flex-row items-center">
                          $ {us.value}
                        </h1>
                      </div>
                    </section>

                    <section
                      className="w-[55%] flex flex-row items-center justify-between rounded-r-[.375rem]
              py-[15px] h-[56px] pr-[10px]"
                    >
                      {ac === 1 ? (
                        <section className="w-[100%] flex flex-row items-start justify-between">
                          <h1 className="text-[14px] font-[400]">{us.apy} %</h1>
                          <div className="flex flex-col items-end ">
                            <h1 className="text-[14px] font-[400]">
                              {us.supply} {us.name}
                            </h1>
                            <h1 className="text-[14px] font-[300] dark:text-[#b9b9b9]">
                              $ {us.vol}
                            </h1>
                          </div>
                        </section>
                      ) : (
                        <section className="w-[100%] flex flex-row items-start justify-between">
                          <h1 className="text-[14px] font-[400]">
                            {us.apy2} %
                          </h1>

                          <div className="flex flex-col items-end ">
                            <h1 className="text-[14px] font-[400]">
                              {us.liq} {us.name}
                            </h1>
                            <h1 className="text-[14px] font-[300] dark:text-[#b9b9b9]">
                              $ {us.liqVol}
                            </h1>
                          </div>
                        </section>
                      )}
                    </section>
                  </div>
                ))}
              </section>
            )}

            {mode === 2 && (
              <h1 className="dark:opacity-[.75] text-[15px] font-[300] text-left pt-[10px]">
                Click on a token to view analytics.
              </h1>
            )}
          </section>
        </section>
      )}

      {page === 2 && (
        <P2
          current={current}
          setPage={setPage}
          showSide={showSide}
          connectWallet={connectWallet}
        />
      )}
    </body>
  );
};

export default LendOverview;
