import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
  } from '@chakra-ui/react'

const Top = () => {

    const words = ["supply", "borrow", "build", "stake"];

  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTyping) {
        setDisplayText(words[currentIndex].substring(0, index));
        setIndex((prevIndex) => prevIndex + 1);
        if (index >= words[currentIndex].length) {
          setIsTyping(false);
        }
      } else {
        setDisplayText(words[currentIndex].substring(0, index));
        setIndex((prevIndex) => prevIndex - 1);
        if (index <= 0) {
          setIsTyping(true);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
        }
      }
    }, 200);

    return () => clearInterval(interval);
  }, [index, isTyping, currentIndex, words]);

  const bb = [
    {
        id: 1,
        title: "$584,483,973.38",
        value: "of value locked"
    },
    {
        id: 2,
        title: "100,000+",
        value: "users"
    },
    {
        id: 3,
        title: "50+",
        value: "integrations"
    },
  ];

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMenuOpen, setIsMenuOpen] = useState(false)

    return(
        <body className="min-h-[100vh] w-[100%] flex flex-col items-center out">
           <header className={`flex flex-row items-center justify-between w-[100%] fixed px-[50px] lg:px-[100px] py-[30px] text-white
            ${isScrolled ? 'backdrop-blur-sm bg-[#01435466]' : 'bg-transparent'} ${!isMenuOpen && "z-40"}`}>
            <img src='https://benqi.fi/images/logo.svg' className='w-[100px] lg:w-[146px]' />

            <div className='lg:flex flex-row items-center justify-between hidden '>
                <Link to="/lend" className='hover:text-[#cccccc] text-[20px] font-[400] pr-[20px]'>Lend and borrow</Link>
                <Link to="/stake" className='hover:text-[#cccccc] text-[20px] font-[400] pr-[20px]'>Liquid staking</Link>
                <Link to="/ig" className='hover:text-[#cccccc] text-[20px] font-[400] pr-[20px]'>Ignite</Link>
                <a href="https://docs.benqi.fi/" className='hover:text-[#cccccc] text-[20px] font-[400]'>Docs</a>
            </div>

            <button className='bg-[#1da1f1] rounded-[.375rem] h-[40px] w-[184px] lg:flex flex-row items-center justify-center
            font-[400] hidden '>
                Apps
            </button>

            <FontAwesomeIcon icon={faBars} className='text-[40px] cursor-pointer lg:hidden flex' onClick={() => {setIsMenuOpen(true)}}/>
        </header>

        <section className='topbg min-h-[100vh] w-[100%] flex flex-row'>

        <section className="flex flex-row items-center justify-between w-[100%] text-white">
        <div className={`flex flex-col items-start lg:pl-[100px] px-[50px] ${!isMenuOpen && "z-30"}`}>
        <h1 className="typewriter text-[4rem] lg;text-[6rem] uppercase mb-[-10px] lg:mb-[-20px]">{displayText}</h1>
            <h1 className="text-[2.25rem] lg:text-[3rem] font-[600]">with BENQI</h1>

            <h1 className="text-[25px] font-[300] pt-[20px]">The DeFi and Web3 protocol for all.</h1>
        </div>
            <img src="https://benqi.fi/images/q-header.png" className={`absolute right-0 lg:relative lg:w-[600px] sm:w-[463px] ${!isMenuOpen && "z-20"}`} />
        </section>
        </section>

        <section className='bg-white py-[20px] lg:h-[165px] flex flex-col lg:flex-row items-center justify-center w-[100%]'>
            {
                bb.map((us, id) => (
                    <div key={id} className="flex flex-col items-center justify-center px-[50px]">
                    <h1 className='text-[#047a90] text-[40px] font-[700]'>{us.title}</h1>
                    <h2 className='text-black text-[22px] font-[400]'>{us.value}</h2>
                    </div>
                ))
            }
        </section>

        <Drawer placement="left" onClose={onClose} isOpen={isMenuOpen} className="z-50 lg:hidden flex">
        
        <DrawerContent className="z-50 lg:hidden flex">
          
          <DrawerBody className="z-50 ">
          <div className='w-[100%] h-[100vh] fixed backdrop-blur-sm bg-[#01435466]' onClick={() => {setIsMenuOpen(false)}}></div>
          <section className='bg-black w-[80%] h-[100vh] fixed z-50 p-[20px]'>
            <div className='flex flex-row items-end justify-end w-[100%] '>
            <i class='bx bx-x text-white text-[50px] cursor-pointer' onClick={() => {setIsMenuOpen(false)}}></i>
            </div>
            <div className='h-[2px] w-[100%] bg-[#085d78] my-[20px]'></div>
            <div className='flex flex-col items-start justify-between text-white'>
                <Link to="/" className='hover:text-[#cccccc] text-[20px] font-[400] pb-[20px]'>Lend and borrow</Link>
                <Link to="/" className='hover:text-[#cccccc] text-[20px] font-[400] pb-[20px]'>Liquid staking</Link>
                <Link to="/" className='hover:text-[#cccccc] text-[20px] font-[400] pb-[20px]'>Ignite</Link>
                <Link to="/" className='hover:text-[#cccccc] text-[20px] font-[400]'>Docs</Link>
            </div>
          </section>

          </DrawerBody>
        </DrawerContent>
      </Drawer>
        </body>
    )
}

export default Top