import { useState } from "react";
import { details } from "../../maps";

const Staking = ({
  theme,
  setConnectWallet,
  showSide,
  connectWallet,
  connected,
}) => {
  const [page, setPage] = useState(1);


  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
        outfit dark:bg-[#003f54] px-[20px] lg:px-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <h1 className="text-[30px] font-[400]">Liquid Staking</h1>
      <h1 className="text-[16px] font-[300] pb-[30px]">
        Stake AVAX and receive sAVAX to unlock your capital
      </h1>

      <section
        className={`flex flex-row items-center rounded-[1rem] border-solid border-[2px]
      h-[48px] w-[100%] lg:w-[320px] p-[1px] mb-[60px] ${
        page === 1 ? "border-[#ff9100] " : "border-[#00b3ed]"
      }`}
      >
        <button
          onClick={() => {
            setPage(1);
          }}
          className={`${
            page === 1
              ? "bg-[#ff9100] text-black"
              : "bg-transparent text-[#00b3ed]"
          } w-[50%] h-[40px] flex flex-row items-center justify-center rounded-[.75rem] text-[20px]`}
        >
          Stake
        </button>
        <button
          onClick={() => {
            setPage(2);
          }}
          className={`${
            page === 2
              ? "bg-[#00b3ed] text-black"
              : "bg-transparent text-[#ff9100]"
          } w-[50%] h-[40px] flex flex-row items-center justify-center rounded-[.75rem] text-[20px]`}
        >
          Unstake
        </button>
      </section>

      <section
        className="lg:w-[900px] w-[100%] flex flex-col items-center justify-center rounded-[1rem]
       dark:bg-[#002430] bg-[#e8f5ff] p-[20px] lg:p-[2.5rem]"
      >
        {connected && (
          <section className="flex flex-col items-start w-[100%] px-[20px] lg:px-[40px] pb-[40px]">
            <div className="flex flex-col items-start w-[100%] border-solid border-b-[1px] border-[#00b3ed] pb-[15px] mb-[15px]">
              <h1 className="text-[#949494] text-[.9375rem]">AVAX balance</h1>
              <h1 className="text-[ 1.125rem] font-[700]">0.0000 AVAX</h1>
            </div>

            <section className="flex flex-row items-start w-[100%]">
              <div className="flex flex-col items-start w-[50%]">
                <h1 className="text-[#949494] text-[.9375rem]">
                  sAVAX balance
                </h1>
                <h1 className="text-[ 1.125rem] font-[700]">0.0000 sAVAX</h1>
              </div>

              <div className="flex flex-col items-end w-[50%]">
                <h1 className="text-[#949494] text-[.9375rem]">APR</h1>
                <h1 className="text-[ 1.125rem] font-[700]">~5.55 %</h1>
              </div>
            </section>
          </section>
        )}

        <section
          className={`${
            page === 1 ? "flex-col" : "flex-col-reverse"
          } flex items-start w-[100%]`}
        >
          {connected && page === 2 ? null : (
            <section
              className={`flex flex-col items-start ${
                page === 1 ? "rounded-t-[.75rem]" : "rounded-b-[.75rem]"
              } ${
                page === 1 && connected ? "rounded-[.75rem]" : null
              } w-[100%] border-solid border-[1px] border-[#ff9100] pt-[1.125rem]
            px-[20px] lg:px-[2.5rem] pb-[3.25rem] bg-white dark:bg-[#001016]`}
            >
              {page === 2 && (
                <div
                  className={`flex flex-row items-center justify-center w-[100%] mt-[-19px] ${
                    showSide || connectWallet ? "z-0" : "z-10"
                  }`}
                >
                  <img
                    src={`${
                      theme === "dark" ? "./images/f.svg" : "./images/f2.svg"
                    }`}
                    className=""
                  />
                </div>
              )}
              <h1 className="text-[20px] pl-[20px] pb-[10px]">
                {page === 1 ? "Stake" : "Receive"}
              </h1>

              <div
                className="flex flex-row items-center justify-between w-[100%] h-[80px]  border-solid border-[1px] border-[#ff9100] rounded-[1rem] 
                px-[1.5rem] py-[1.125rem]"
              >
                <input
                  className="w-[100%] lg:w-[90%] flex flex-row items-center text-right lg:text-left text-[28px] bg-transparent"
                  placeholder="0.00"
                />
                <div className="lg:flex flex-row items-center justify-end hidden w-[100%] pt-[10px]">
                  <h1
                    className={` ${page === 2 ? "flex" : "hidden"} pr-[10px]`}
                  >
                    ~$855.24
                  </h1>
                  <h1 className="uppercase hidden lg:flex flex-row items-center text-[22px]">
                    <img
                      src="./images/avax.svg"
                      className="w-[30px] mr-[10px]"
                    />
                    avax
                  </h1>
                </div>
              </div>
              <div className="flex flex-row items-center justify-end lg:hidden w-[100%] pt-[10px]">
                {!connected && <h1 className="pr-[10px]">~$855.24</h1>}
                <h1 className="uppercase lg:hidden flex flex-row items-center text-[22px]">
                  <img src="./images/avax.svg" className="w-[30px] mr-[10px]" />
                  avax
                </h1>
              </div>
              {connected && (
                <section className="flex flex-row items-center justify-center w-[100%] py-[20px]">
                  <button
                    className="h-[56px] w-[132px] flex flex-col items-center justify-center
              border-solid border-[1px] border-[#707070] text-[#707070] rounded-[.75rem]"
                  >
                    Stake
                  </button>
                </section>
              )}

              {page === 1 && !connected ? (
                <h1 className="text-[16px] pt-[10px] lg:pl-[20px] flex flex-row items-center lg:justify-start justify-end w-[100%]">
                  1 AVAX ≈ 0.8683 sAVAX
                </h1>
              ) : null}

              {connected && (
                <section className="flex flex-col items-start w-[100%] text-[0.875rem]">
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1>You will receive</h1>
                    <h1>~0.00 sAVAX</h1>
                  </div>
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1 className="text-left">Exchange rate</h1>
                    <h1 className="pl-[10px] text-right">
                      1 AVAX ≈ 0.868104 sAVAX
                    </h1>
                  </div>{" "}
                </section>
              )}
            </section>
          )}
          {page === 1 && !connected ? (
            <div
              className={`flex flex-row items-center justify-center w-[100%] mt-[-1px] ${
                showSide || connectWallet ? "z-0" : "z-10"
              }`}
            >
              <img
                src={`${
                  theme === "dark" ? "./images/orTri.svg" : "./images/orLi.svg"
                }`}
                className=""
              />
            </div>
          ) : null}
          {!connected && page === 2 ? (
            <div className="w-[100%] h-[20px]"></div>
          ) : null}

          {!connected && (
            <div
              className={`flex flex-row items-center justify-center w-[100%] ${
                page === 1 ? "mb-[-20px]" : "mt-[-1px] mb-[-20px]"
              } ${showSide || connectWallet ? "z-0" : "z-10"}`}
            >
              <img
                src={`${
                  theme === "dark" ? "./images/darkBl.svg" : "./images/bl.svg"
                }`}
                className=""
              />
            </div>
          )}
          {connected && page === 1 ? null : (
            <section
              className={`flex flex-col items-start w-[100%] border-solid border-[1px] border-[#00b3ed] ${
                page === 1 && !connected
                  ? "rounded-b-[.75rem]"
                  : "rounded-t-[.75rem]"
              } pt-[1.125rem] ${connected && page === 2 && "rounded-[.75rem]"}
            px-[20px] lg:px-[2.5rem] pb-[3.25rem] bg-white dark:bg-[#001016]`}
            >
              <div className="flex flex-row items-center justify-between w-[100%]">
                <h1 className="text-[20px] pl-[20px] pb-[10px]">
                  {page === 1 ? "Receive" : "Unstake"}
                </h1>

                {connected && <h1 className="text-[0.875rem]">MAX</h1>}
              </div>

              <div
                className="flex flex-row items-center justify-between w-[100%] h-[80px]  border-solid border-[1px] border-[#00b3ed] rounded-[1rem] 
                px-[1.5rem] py-[1.125rem]"
              >
                <input
                  className="w-[100%] lg:w-[90%] flex flex-row items-center text-right lg:text-left text-[28px] bg-transparent"
                  placeholder="0.00"
                />

                <div className="lg:flex flex-row items-center justify-end hidden w-[100%] pt-[10px]">
                  <h1
                    className={` ${page === 1 ? "flex" : "hidden"} pr-[10px]`}
                  >
                    ~$855.24
                  </h1>
                  <h1 className="hidden lg:flex flex-row items-center text-[22px]">
                    <img src="./images/sa.png" className="w-[30px] mr-[10px]" />
                    sAVAX
                  </h1>
                </div>
              </div>
              <div className="flex flex-row items-center justify-end lg:hidden w-[100%] pt-[10px]">
                {!connected && <h1 className="pr-[10px]">~$855.24</h1>}
                <h1 className="uppercase lg:hidden flex flex-row items-center">
                  <img src="./images/sa.png" className="w-[30px] mr-[10px]" />
                  sAVAX
                </h1>
              </div>

              {page === 2 && !connected && (
                <h1 className="text-[16px] pt-[10px] lg:pl-[20px] flex flex-row items-center lg:justify-start justify-end w-[100%]">
                  1 sAVAX ≈ 1.123 AVAX
                </h1>
              )}

              {connected && (
                <section className="flex flex-row items-center justify-center w-[100%] py-[20px]">
                  <button
                    className="h-[56px] w-[132px] flex flex-col items-center justify-center
              border-solid border-[1px] border-[#707070] text-[#707070] rounded-[.75rem]"
                  >
                    Unstake
                  </button>
                </section>
              )}

              {connected && (
                <section className="flex flex-col items-start w-[100%] text-[0.875rem]">
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1>You will receive</h1>
                    <h1>~0.00 AVAX</h1>
                  </div>
                  <div className="flex flex-row items-center justify-between w-[100%]">
                    <h1 className="text-left">Exchange rate</h1>
                    <h1 className="pl-[10px] text-right">
                      1 AVAX ≈ 0.868104 AVAX
                    </h1>
                  </div>{" "}
                </section>
              )}

              {connected && (
                <section className="flex flex-row items-start text-left pt-[20px] text-[0.875rem] text-[#00b3ed]">
                  <i class="bx bx-error-circle mt-[2px] pr-[10px]"></i>
                  <h1>
                    Unstaking directly from BENQI Liquid Staking will incur the
                    unstaking cooldown period. If you wish to instantly receive
                    AVAX, we recommend trading sAVAX directly on an exchange.
                  </h1>
                </section>
              )}
            </section>
          )}
        </section>

        {!connected && (
          <div className="flex flex-row items-center justify-center w-[100%] pt-[40px]">
            <button
              className="h-[42px] flex flex-row items-center justify-center w-[203px] border-solid border-[1px] border-[#00b3ed]
          dark:bg-[#003f54] bg-[#d7edfd] text-[#00b3ed] rounded-[.75rem] dark:hover:text-[#91e4ff]"
              onClick={() => {
                setConnectWallet(true);
              }}
            >
              Connect wallet
            </button>
          </div>
        )}

        <section className="flex flex-col items-start w-[100%] border-solid border-b-[1px] border-[#00b3ed] pb-[10px] mb-[10px] pt-[40px]">
          <h1 className="text-[1.375rem] ">Liquid Staking Statistics</h1>
        </section>

        <section className="flex flex-col items-start w-[100%]">
          {details.map((us, id) => (
            <div
              key={id}
              className="flex flex-row items-center justify-between w-[100%] pb-[10px] text-[14px]"
            >
              <h1 className="flex flex-row items-center">
                {us.t1}{" "}
                {us.more && (
                  <div
                    className="w-[12px] h-[12px] rounded-[50%] ml-[10px] border-solid border-[1px] border-[#e1ecf5] 
                        flex flex-row items-center justify-center"
                  >
                    <i class="bx bx-question-mark text-[10px]"></i>
                  </div>
                )}{" "}
              </h1>
              <h1>{us.t2}</h1>
            </div>
          ))}
        </section>
      </section>
    </body>
  );
};

export default Staking;
