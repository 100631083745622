import IgSide from "./IgSide";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  Drawer,
  useDisclosure,
} from "@chakra-ui/react";
import Connect from "../Connect";

const IgLayout = ({ theme, setTheme, connected }) => {
  const [showSide, setShowSide] = useState(false);
  const { onClose } = useDisclosure();
  const [connectWallet, setConnectWallet] = useState(false);

  return (
    <body className="flex flex-col lg:flex-row items-start w-[100%]">
      <div className="lg:hidden flex flex-col items-end justify-end w-[100%] p-[20px] dark:bg-[#2a3b49]">
        <i
          class="bx bx-menu text-[40px] dark:text-white cursor-pointer"
          onClick={() => {
            setShowSide(true);
          }}
        ></i>
      </div>
      <section className="w-[17%] hidden lg:fixed lg:block">
        <IgSide
          setTheme={setTheme}
          theme={theme}
          connected={connected}
          setConnectWallet={setConnectWallet}
          connectWallet={connectWallet}
          setShowSide={setShowSide}
        />
      </section>

      <section className="w-[100%] lg:pl-[17%]">
        <Outlet />
      </section>

      <Modal isOpen={showSide} onClose={showSide} className="lg:hidden">
        <ModalContent className="flex flex-col items-center w-[100%] min-h-[100vh] z-50 fixed lg;hidden">
          <div
            className="bg-white dark:bg-[#334758] bg-opacity-40 dark:bg-opacity-90 w-[100%] min-h-[100vh] absolute z-40"
            onClick={() => {
              setShowSide(false);
            }}
          ></div>

          <ModalBody className="flex z-50 flex-col items-center justify-start pt-[150px] min-h-[100vh] w-[100%]">
            <Drawer
              placement="left"
              isOpen={showSide}
              onClose={onClose}
              className="fixed left-0 lg:hidden flex-col items-center justify-center z-50 bg-red-500 pb-[20px] min-h-[100vh]"
            >
              <section className="flex flex-col items-center w-[60%] h-[100%] fixed top-0 min-h-[100vh]">
                <IgSide
                  setTheme={setTheme}
                  theme={theme}
                  connected={connected}
                  setConnectWallet={setConnectWallet}
                  connectWallet={connectWallet}
                  setShowSide={setShowSide}
                />
              </section>
            </Drawer>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Connect
        setConnectWallet={setConnectWallet}
        connectWallet={connectWallet}
        onClose={onClose}
      />
    </body>
  );
};

export default IgLayout;
