
const b = [
    {
        id: 1,
        name: "AVAX",
        icon: "https://app.benqi.fi/svgs/tokens/avax.svg"
    },
    {
        id: 2,
        name: "sAVAX",
        icon: "https://app.benqi.fi/svgs/tokens/savax.svg"
    },
    {
        id: 3,
        name: "USDt",
        icon: "https://app.benqi.fi/svgs/tokens/usdtn.svg"
    },
    {
        id: 4,
        name: "USDC",
        icon: "https://app.benqi.fi/svgs/tokens/usdcn.svg"
    },
    {
        id: 5,
        name: "DAI.e",
        icon: "https://app.benqi.fi/svgs/tokens/dai.svg"
    },
    {
        id: 6,
        name: "BTC.b",
        icon: "https://app.benqi.fi/svgs/tokens/btcb.svg"
    },
    {
        id: 7,
        name: "WETH.e",
        icon: "https://app.benqi.fi/svgs/tokens/eth.svg"
    },
    {
        id: 8,
        name: "LINK.e",
        icon: "https://app.benqi.fi/svgs/tokens/link.svg"
    },
    {
        id: 9,
        name: "USDC.e",
        icon: "https://app.benqi.fi/svgs/tokens/usdc.svg"
    },
    {
        id: 10,
        name: "USDT.e",
        icon: "https://app.benqi.fi/svgs/tokens/usdt.svg"
    },
]

export default b