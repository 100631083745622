import Top from "./Top"
import Footer from "./Footer"
const Layout = () => {
    return(
        <section className="overflow-x-hidden">
        <Top />
        <Footer />
        </section>
    )
}

export default Layout