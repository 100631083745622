

const br = [
    {
        id: 1,
        title: "Avalanche Bridge",
        icon: "https://app.benqi.fi/svgs/tokens/avax.svg",
        body: "Official Avalanche Bridge for the bridging of assets between Bitcoin, Ethereum, and Avalanche.",
        link: "https://core.app/bridge/"
    },
    {
        id: 2,
        title: "cBridge",
        icon: "https://app.benqi.fi/svgs/bridges/celer.svg",
        body: "Celer Bridge for the bridging of assets from multiple chains and Avalanche.",
        link: "https://cbridge.celer.network/#/transfer"
    },
    {
        id: 3,
        title: "Synapse Bridge",
        icon: "https://app.benqi.fi/svgs/bridges/synapse.svg",
        body: "Synapse Bridge for the bridging of assets from multiple chains and Avalanche.",
        link: "https://synapseprotocol.com/?outputChain=43114"
    },
    {
        id: 4,
        title: "Bungee",
        icon: "https://app.benqi.fi/svgs/bridges/bungee.svg",
        body: "Bungee Bridge for the bridging of assets from multiple chains and Avalanche.",
        link: "https://www.bungee.exchange/"
    },
    {
        id: 5,
        title: "Symbiosis",
        icon: "https://app.benqi.fi/svgs/bridges/symbiosis.svg",
        body: "Symbiosis Bridge for the bridging of assets from multiple chains and Avalanche.",
        link: "https://app.symbiosis.finance/"
    },
]

export default br