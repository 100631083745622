const Rewards = ({
  theme,
  setConnectWallet,
  connected,
}) => {
  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-white text-center
        outfit dark:bg-[#003f54] px-[20px] lg:px-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <section className="lg:w-[900px] w-[100%] flex flex-col items-start rounded-[1rem] dark:bg-[#002430] bg-[#e8f5ff] py-[15px] px-[20px]">
        <h1 className="text-[30px] text-black dark:text-white font-[300]">
          Rewards
        </h1>
        <h1 className="text-black text-[15px] font-[300] dark:text-white text-left">
          Earn rewards by using the protocol and/or depositing your Pangolin DEX
          Liquidity Provider (PGL) tokens.
        </h1>

        <section className="flex flex-col items-start w-[100%]">
          <div
            className="bg-white dark:bg-[#001016] text-black dark:text-white cursor-pointer
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[.375rem] py-[15px] flex flex-col items-start justify-between
      px-[20px] cursor-auto"
          >
            <div className="flex flex-row items-center justify-between text-left w-[100%] border-solid border-b-[1px] dark:border-[#08171d] border-[#e3f8ff] pb-[20px]">
              <div className="flex flex-row items-center ">
                <img
                  src={`${
                    theme === "dark"
                      ? "https://app.benqi.fi/svgs/tokens/benqi-light.svg"
                      : "https://app.benqi.fi/svgs/tokens/benqi.svg"
                  }`}
                  className="w-[20px] rounded-[50%]"
                />
                <h1 className="pl-[10px] text-[18px] font-[400]">
                  Protocol rewards (QI)
                </h1>
              </div>
              <h1 className={`text-[15px] font-[300]`}>1 QI = $0.02</h1>
            </div>

            <section className="flex flex-col lg:flex-row items-end lg:items-center justify-between w-[100%] pt-[20px]">
              <section className="flex flex-row items-start justify-between w-[100%] lg:w-[70%]">
                <div className="flex flex-col items-start w-[50%]">
                  <h1 className="text-[13px] font-[300]">Unclaimed</h1>
                  <h1 className="font-[400]">{connected ? "$0.00" : "—"} </h1>
                </div>
                <div className="flex flex-col items-start w-[50%]">
                  <h1 className="text-[13px] font-[300]">Unclaimed Value</h1>
                  <h1 className="font-[400]">{connected ? "$0.00" : "—"} </h1>
                </div>
              </section>

              {connected ? (
                <button
                  className="bg-[#00b3ed33] flex flex-row items-center justify-center w-[80px] h-[40px]
                       rounded-[5px] font-[400] text-[16px] text-black"
                >
                  Claim
                </button>
              ) : (
                <button
                  onClick={() => {
                    setConnectWallet(true);
                  }}
                  className="bg-[#00b3ed] flex flex-row items-center justify-center w-[50%] lg:w-[145px] h-[40px] rounded-[5px] font-[400] text-[16px] text-black"
                >
                  Connect wallet
                </button>
              )}
            </section>
          </div>

          <div
            className="bg-white dark:bg-[#001016] text-black dark:text-white cursor-pointer
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[.375rem] py-[15px] flex flex-col items-start justify-between
      px-[20px] cursor-auto"
          >
            <div className="flex flex-row items-center justify-between text-left w-[100%] border-solid border-b-[1px] dark:border-[#08171d] border-[#e3f8ff] pb-[20px]">
              <div className="flex flex-row items-center ">
                <img
                  src="https://app.benqi.fi/svgs/tokens/avax.svg"
                  className="w-[20px] rounded-[50%]"
                />
                <h1 className="pl-[10px] text-[18px] font-[400]">
                  Protocol rewards (AVAX)
                </h1>
              </div>
            </div>

            <section className="flex flex-col lg:flex-row items-end lg:items-center justify-between w-[100%] pt-[20px]">
              <section className="flex flex-row items-start justify-between w-[100%] lg:w-[70%]">
                <div className="flex flex-col items-start w-[50%]">
                  <h1 className="text-[13px] font-[300]">Unclaimed</h1>
                  <h1 className="font-[400]">{connected ? "$0.00" : "—"} </h1>
                </div>
                <div className="flex flex-col items-start w-[50%]">
                  <h1 className="text-[13px] font-[300]">Unclaimed Value</h1>
                  <h1 className="font-[400]">{connected ? "$0.00" : "—"} </h1>
                </div>
              </section>

              {connected ? (
                <button
                  className="bg-[#00b3ed33] flex flex-row items-center justify-center w-[80px] h-[40px]
                       rounded-[5px] font-[400] text-[16px] text-black"
                >
                  Claim
                </button>
              ) : (
                <button
                  onClick={() => {
                    setConnectWallet(true);
                  }}
                  className="bg-[#00b3ed] flex flex-row items-center justify-center w-[50%] lg:w-[145px] h-[40px] rounded-[5px] font-[400] text-[16px] text-black"
                >
                  Connect wallet
                </button>
              )}
            </section>
          </div>

          <div
            className="bg-white dark:bg-[#001016] text-black dark:text-white cursor-pointer
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[.375rem] py-[15px] flex flex-col items-start justify-between
      px-[20px] cursor-auto"
          >
            <div className="flex flex-row items-center justify-between text-left w-[100%] border-solid border-b-[1px] dark:border-[#08171d] border-[#e3f8ff] pb-[20px]">
              <div className="flex flex-row items-center ">
                <img
                  src="https://app.benqi.fi/svgs/traderjoe.svg"
                  className="w-[20px] rounded-[50%]"
                />
                <h1 className="pl-[10px] text-[18px] font-[400]">
                  Trader Joe DEX rewards (QI-AVAX JLP)
                </h1>
              </div>
            </div>

            <section className="flex flex-col items-end  justify-between w-[100%] pt-[20px]">
              <section className="flex flex-col lg:flex-row items-start justify-between w-[100%]">
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Deposited tokens
                  </h1>
                  <h1 className="font-[400]">
                    {connected ? "0.000000 JLP" : "—"}{" "}
                  </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    LP breakdown
                  </h1>
                  <h1 className="font-[400]">
                    {connected ? "0.0000 QI + 0.0000 AVAX" : "—"}{" "}
                  </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Total deposited value
                  </h1>
                  <h1 className="font-[400]">$1,615,190.40</h1>
                </div>
              </section>

              <section className="flex flex-col lg:flex-row items-start justify-between w-[100%] pt-[10px] pb-[30px]">
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Deposited value
                  </h1>
                  <h1 className="font-[400]">{connected ? "$0.00" : "—"} </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Unclaimed rewards
                  </h1>
                  <h1 className="font-[400]">
                    {connected ? "0.0000 QI" : "—"}{" "}
                  </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    APR
                  </h1>
                  <h1 className="font-[400]">9.65 %</h1>
                </div>
              </section>

              {connected ? (
                <section className="flex flex-row items-center justify-between w-[100%] lg:w-[23%]">
                  <button
                    className="bg-[#00b3ed33] flex flex-row items-center justify-center w-[49%] lg:w-[80px] h-[40px]
                       rounded-[5px] font-[400] text-[16px] text-black"
                  >
                    Deposit
                  </button>
                  <button
                    className="bg-[#00b3ed33] flex flex-row items-center justify-center w-[49%] lg:w-[80px] h-[40px]
                       rounded-[5px] font-[400] text-[16px] text-black"
                  >
                    Claim
                  </button>
                </section>
              ) : (
                <button
                  onClick={() => {
                    setConnectWallet(true);
                  }}
                  className="bg-[#00b3ed] flex flex-row items-center justify-center w-[50%] lg:w-[145px] h-[40px] rounded-[5px] font-[400] text-[16px] text-black"
                >
                  Connect wallet
                </button>
              )}
            </section>
          </div>

          <div
            className="bg-white dark:bg-[#001016] text-black dark:text-white cursor-pointer
         text-[16px] font-[500] w-[100%] mt-[20px] rounded-[.375rem] py-[15px] flex flex-col items-start justify-between
      px-[20px] cursor-auto"
          >
            <div className="flex flex-row items-center justify-between text-left w-[100%] border-solid border-b-[1px] dark:border-[#08171d] border-[#e3f8ff] pb-[20px]">
              <div className="flex flex-row items-center ">
                <img
                  src="https://app.benqi.fi/svgs/pangolin.svg"
                  className="w-[20px] rounded-[50%]"
                />
                <h1 className="pl-[10px] text-[18px] font-[400]">
                  Pangolin DEX rewards (QI-AVAX PGL)
                </h1>
              </div>
            </div>

            <div className="w-[100%] bg-[#d7edfd] rounded-[.375rem] p-[.75rem] text-[15px] font-[400] text-left dark:bg-[#002430]">
              The Pangolin QI-AVAX pool is no longer rewarded. To continue
              earning rewards, migrate your liquidity to Trader Joe and deposit
              your JLP tokens on BENQI.
            </div>

            <section className="flex flex-col items-end  justify-between w-[100%] pt-[20px]">
              <section className="flex flex-col lg:flex-row items-start justify-between w-[100%]">
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Deposited tokens
                  </h1>
                  <h1 className="font-[400]">
                    {connected ? "0.000000000000000000 PGL" : "—"}{" "}
                  </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    LP breakdown
                  </h1>
                  <h1 className="font-[400]">
                    {connected ? "0.0000 QI + 0.0000 AVAX" : "—"}{" "}
                  </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Total deposited value
                  </h1>
                  <h1 className="font-[400]">$1,615,190.40</h1>
                </div>
              </section>

              <section className="flex flex-col lg:flex-row items-start justify-between w-[100%] pt-[10px] pb-[30px]">
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Deposited value
                  </h1>
                  <h1 className="font-[400]">{connected ? "$0.00" : "—"} </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    Unclaimed rewards
                  </h1>
                  <h1 className="font-[400]">
                    {connected ? "0.0000 QI" : "—"}{" "}
                  </h1>
                </div>
                <div className="flex flex-col items-start w-[100%] lg:w-[50%]">
                  <h1 className="text-[13px] font-[300] dark:text-[#d2d2d2] text-[#3e444e]">
                    APR
                  </h1>
                  <h1 className="font-[400]">9.65 %</h1>
                </div>
              </section>

              {connected ? (
                <button
                  className="bg-[#00b3ed33] flex flex-row items-center justify-center w-[80px] h-[40px]
                       rounded-[5px] font-[400] text-[16px] text-black"
                >
                  Claim
                </button>
              ) : (
                <button
                  onClick={() => {
                    setConnectWallet(true);
                  }}
                  className="bg-[#00b3ed] flex flex-row items-center justify-center w-[50%] lg:w-[145px] h-[40px] rounded-[5px] font-[400] text-[16px] text-black"
                >
                  Connect wallet
                </button>
              )}
            </section>
          </div>
        </section>
      </section>
    </body>
  );
};

export default Rewards;
