import { v } from "../../maps";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useState } from "react";

const Validators = ({ theme }) => {
  const [page, setPage] = useState(1);

  const top = [
    {
      id: 1,
      title: "Pay as you go",
    },
    {
      id: 2,
      title: "Stake",
    },
  ];
  const [actW, setActw] = useState(false);
  const [weeks, setWeeks] = useState(2);

  const weeksNum = [
    {
      id: 1,
      num: 2,
    },
    {
      id: 2,
      num: 4,
    },
    {
      id: 3,
      num: 8,
    },
    {
      id: 3,
      num: 12,
    },
  ];

  const [paymentToken, setPaymentToken] = useState({
    name: "avax",
    icon: "./images/avax.svg",
  });

  const tokens = [
    {
      id: 1,
      name: "avax",
      icon: "./images/avax.svg",
    },
    {
      id: 2,
      name: "usdc",
      icon: "./images/usdc.svg",
    },
    {
      id: 3,
      name: "qi",
      icon: "./images/qi.svg",
    },
  ];

  const [actT, setActT] = useState(false);

  const payg = (
    <body className="flex flex-col items-center w-[100%] dark:bg-[#3a5163] bg-white rounded-b-[.75rem] p-[20px] outfit">
      <div className="w-[100%] dark:bg-[#48657a] bg-[#f5f4e4] rounded-[.375rem] py-[.75rem] px-[1.25rem]">
        <h1 className="text-[16px] font-[500] text-left">
          Get a <b>5 % discount</b> on the fee when you pay in QI.{" "}
          <a className="text-[#ff5b1f]">Click here to get QI</a>
        </h1>
      </div>

      <section className="flex flex-col lg:flex-row items-start justify-between w-[100%] py-[30px] pt-[50px]">
        <div className="w-[100%] lg:w-[78%] flex flex-col items-start">
          <input
            className="h-[54px] w-[100%] flex flex-row items-center px-[.75rem] text-[1.25rem] rounded-[.5rem] bg-transparent
          border-solid border-[1px] border-[#cdcdcd] placeholder:text-[#b4b4b4] dark:border-[#577b94] dark:text-white text-black"
            placeholder="Node ID"
          />
          <input
            className="h-[54px] w-[100%] flex flex-row items-center px-[.75rem] text-[1.25rem] rounded-[.5rem] bg-transparent
          border-solid border-[1px] border-[#cdcdcd] placeholder:text-[#b4b4b4] dark:border-[#577b94] my-[20px] dark:text-white text-black"
            placeholder="BLS Public Key (0x...)"
          />
          <input
            className="h-[54px] w-[100%] flex flex-row items-center px-[.75rem] text-[1.25rem] rounded-[.5rem] bg-transparent
          border-solid border-[1px] border-[#cdcdcd] placeholder:text-[#b4b4b4] dark:border-[#577b94] dark:text-white text-black"
            placeholder="BLS Signature (0x...)"
          />

          <div className="w-[100%] flex flex-row items-center justify-end">
            <a
              className="flex flex-row items-center text-[14px] pt-[10px]"
              href="https://docs.avax.network/reference/avalanchego/info-api#infogetnodeid"
            >
              <i class="bx bx-info-circle pr-[5px]"></i>
              What are these?
            </a>
          </div>
        </div>

        <div className="lg:w-[20%] w-[100%] pt-[20px] lg:pt-[0px] lg:mt-[-20px]">
          <h1 className="font-[500] w-[100%] items-start flex flex-row">
            Staking duration
          </h1>

          <button
            className="h-[52px] flex flex-row items-center justify-center w-[100%] dark:bg-[#48657a] bg-[#e3e2d3] rounded-[.5rem]"
            onClick={() => {
              setActw(!actW);
            }}
          >
            <h1 className="text-[1.25rem] font-[500] pr-[5px]">
              {weeks} weeks
            </h1>
            <h1
              className="dark:bg-white bg-black w-[16px] h-[16px] flex flex-row items-center justify-center dark:text-black text-white
       rounded-[50%]"
            >
              {actW ? (
                <i class="bx bx-chevron-up"></i>
              ) : (
                <i class="bx bx-chevron-down"></i>
              )}
            </h1>
          </button>

          {actW && (
            <div
              className="flex flex-col items-center justify-center dark:bg-[#48657a] bg-[#e3e2d3] rounded-[.5rem] mt-[5px]
      absolute lg:relative lg:w-[100%] w-[78%] sm:w-[90%]"
            >
              {weeksNum.map((us, id) => (
                <div
                  key={id}
                  className={`py-[5px] text-[20px] ${
                    us.num === weeks && "hidden"
                  } cursor-pointer
          hover:bg-[#e2e8f0] w-[100%] dark:hover:text-black rounded-[.5rem]`}
                  onClick={() => {
                    setWeeks(us.num);
                    setActw(false);
                  }}
                >
                  {us.num} {"weeks"}
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <section className="flex flex-col justify-center items-center lg:justify-start lg:flex-row lg:items-start w-[100%] ">
        <div className="flex flex-col items-start  w-[100%] lg:w-[170px]">
          <h1 className="font-[500]">Payment token</h1>
          <button
            className="h-[52px] flex flex-row items-center justify-center w-[100%] dark:bg-[#48657a] bg-[#e3e2d3] rounded-[.5rem] px-[10px]"
            onClick={() => {
              setActT(!actT);
            }}
          >
            <img src={paymentToken.icon} className="w-[28px]" />
            <h1 className="text-[1.25rem] font-[500] pr-[5px] flex flex-row items-center uppercase px-[20px] lg:px-[10px]">
              {paymentToken.name}
            </h1>
            <h1
              className="dark:bg-white bg-black w-[16px] h-[16px] flex flex-row items-center justify-center dark:text-black text-white
       rounded-[50%] ml-[20px] lg:ml-[10px]"
            >
              {actT ? (
                <i class="bx bx-chevron-up"></i>
              ) : (
                <i class="bx bx-chevron-down"></i>
              )}
            </h1>
          </button>
          {actT && (
            <div
              className="flex flex-col items-center justify-center dark:bg-[#48657a] bg-[#e3e2d3] rounded-[.5rem] mt-[80px] lg:mt-[5px]
      absolute lg:relative lg:w-[100%] w-[78%] sm:w-[90%]"
            >
              {tokens.map((us, id) => (
                <div
                  key={id}
                  className={`py-[5px] text-[20px] ${
                    us.name === paymentToken.name && "hidden"
                  } cursor-pointer
          hover:bg-[#e2e8f0] w-[100%] dark:hover:text-black rounded-[.5rem] flex flex-row items-center px-[10px]`}
                  onClick={() => {
                    setPaymentToken({
                      name: us.name,
                      icon: us.icon,
                    });
                    setActT(false);
                  }}
                >
                  <img src={us.icon} className="w-[28px]" />
                  <h1 className="uppercase text-[20px] pl-[5px]">{us.name}</h1>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col items-center lg:items-start pl-[20px] w-[100%] lg:w-[170px]">
          <h1 className="font-[500]">Amount</h1>
          <div className="h-[52px] flex flex-row items-center justify-center w-[100%] bg-transparent rounded-[.5rem]">
            <h1 className="text-[20px] font-[400]">382.4</h1>
            <h1 className="text-[1.25rem] font-[500] pr-[5px] flex flex-row items-center uppercase px-[10px]">
              {paymentToken.name}
            </h1>
            <img src={paymentToken.icon} className="w-[28px]" />
          </div>
        </div>
      </section>
      <h1 className="text-[#6b7280] text-center text-[.875rem] font-[500] pt-[20px]">
        Please note that it may take up to 48 hours for the node to start
        validating.
      </h1>
    </body>
  );

  const stake = (
    <body className="flex flex-col items-center w-[100%] dark:bg-[#3a5163] bg-white rounded-b-[.75rem] p-[20px] outfit">
      <section className="flex flex-col lg:flex-row items-start justify-between w-[100%] pb-[30px] pt-[20px]">
        <div className="w-[100%] lg:w-[78%] flex flex-col items-start">
          <input
            className="h-[54px] w-[100%] flex flex-row items-center px-[.75rem] text-[1.25rem] rounded-[.5rem] bg-transparent
        border-solid border-[1px] border-[#cdcdcd] placeholder:text-[#b4b4b4] dark:border-[#577b94] dark:text-white text-black"
            placeholder="Node ID"
          />
          <input
            className="h-[54px] w-[100%] flex flex-row items-center px-[.75rem] text-[1.25rem] rounded-[.5rem] bg-transparent
        border-solid border-[1px] border-[#cdcdcd] placeholder:text-[#b4b4b4] dark:border-[#577b94] my-[20px] dark:text-white text-black"
            placeholder="BLS Public Key (0x...)"
          />
          <input
            className="h-[54px] w-[100%] flex flex-row items-center px-[.75rem] text-[1.25rem] rounded-[.5rem] bg-transparent
        border-solid border-[1px] border-[#cdcdcd] placeholder:text-[#b4b4b4] dark:border-[#577b94] dark:text-white text-black"
            placeholder="BLS Signature (0x...)"
          />

          <div className="w-[100%] flex flex-row items-center justify-end">
            <a
              className="flex flex-row items-center text-[14px] pt-[10px]"
              href="https://docs.avax.network/reference/avalanchego/info-api#infogetnodeid"
            >
              <i class="bx bx-info-circle pr-[5px]"></i>
              What are these?
            </a>
          </div>
        </div>

        <div className="lg:w-[20%] w-[100%] pt-[20px] lg:pt-[0px] lg:mt-[-20px]">
          <h1 className="font-[500] w-[100%] items-start flex flex-row">
            Staking duration
          </h1>

          <button
            className="h-[52px] flex flex-row items-center justify-center w-[100%] dark:bg-[#48657a] bg-[#e3e2d3] rounded-[.5rem]"
            onClick={() => {
              setActw(!actW);
            }}
          >
            <h1 className="text-[1.25rem] font-[500] pr-[5px]">
              {weeks} weeks
            </h1>
            <h1
              className="dark:bg-white bg-black w-[16px] h-[16px] flex flex-row items-center justify-center dark:text-black text-white
     rounded-[50%]"
            >
              {actW ? (
                <i class="bx bx-chevron-up"></i>
              ) : (
                <i class="bx bx-chevron-down"></i>
              )}
            </h1>
          </button>

          {actW && (
            <div
              className="flex flex-col items-center justify-center dark:bg-[#48657a] bg-[#e3e2d3] rounded-[.5rem] mt-[5px]
    absolute lg:relative lg:w-[100%] w-[78%] sm:w-[90%]"
            >
              {weeksNum.map((us, id) => (
                <div
                  key={id}
                  className={`py-[5px] text-[20px] ${
                    us.num === weeks && "hidden"
                  } cursor-pointer
        hover:bg-[#e2e8f0] w-[100%] dark:hover:text-black rounded-[.5rem]`}
                  onClick={() => {
                    setWeeks(us.num);
                    setActw(false);
                  }}
                >
                  {us.num} {"weeks"}
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <section className="flex flex-col items-start justfiy-start w-[100%]">
        <h1 className="font-[500] pl-[20px] pb-[5px]">Stake amount</h1>

        <section className="flex flex-row items-center justify-between w-[100%]">
          <div
            className="flex flex-row items-center justify-between w-[49%] border-solid border-[1px] border-[#cdcdcd] rounded-[8px]
     h-[54px] px-[10px]"
          >
            <input
              className="w-[80%] bg-transparent h-[100%] text-[20px] font-[500]"
              placeholder="0"
            />
            <h1 className="text-[16px] font-[400] flex flex-row items-center pl-[10px]">
              AVAX
              <img src="./images/avax.svg" className="w-[28px] ml-[5px]" />
            </h1>
          </div>

          <div
            className="flex flex-row items-center justify-between w-[49%] border-solid border-[1px] border-[#cdcdcd] rounded-[8px]
     h-[54px] px-[10px]"
          >
            <input
              className="w-[80%] bg-transparent h-[100%] text-[20px] font-[500]"
              placeholder="353181.26861106895"
              disabled
              type="number"
            />
            <h1 className="text-[16px] font-[400] flex flex-row items-center pl-[10px]">
              QI
              <img src="./images/qi.svg" className="w-[28px] ml-[5px]" />
            </h1>
          </div>
        </section>
      </section>

      <section className="flex flex-row w-[100%]">
        <div class="theslider">
          <form oninput="amount.value=rangeInput17.value">
            <input
              type="range"
              id="rangeInput17"
              name="rangeInput17"
              step="10"
              value="0"
              min="0"
              max="250"
              oninput="updateValue17()"
            />
          </form>
        </div>
      </section>

      <button
        className="bg-[#ff5b22] text-white dark:text-[#001f29] text-[1.5rem] font-[500] w-[148px] lg:w-[200px]
        mt-[30px] rounded-[.375rem] py-[10px] flex flex-row items-center justify-center hover:dark:text-white"
      >
        Approve
      </button>

      <h1 className="text-[#6b7280] text-center text-[.875rem] font-[500] pt-[20px]">
        Please note that it may take up to 48 hours for the node to start
        validating.
      </h1>
    </body>
  );

  return (
    <body
      className="w-[100%] flex flex-col items-center justify-center min-h-[100vh] bg-[#f2f1e2] text-center
        outfit dark:bg-[#2a3b49] px-[20px] lg:px-[200px] dark:text-white text-[#001f29] pt-[10px] lg:pt-[120px] pb-[40px]"
    >
      <img
        src={` ${
          theme === "dark" ? "./images/igLight.svg" : "./images/iglogo.svg"
        }`}
        className="hidden lg:flex w-[240px]"
      />

      <h1 className=" py-[50px] text-[1.25rem] font-[700] ">
        VALIDATORS. VALIDATORS. VALIDATORS.
      </h1>

      <section>
        <Accordion allowMultiple className="">
          <AccordionItem className="rounded-[20px]  bg-white dark:bg-[#3a5163] w-[100%] lg:w-[900px]">
            <h2 className="h-[64px] flex flex-row items-center justify-center w-[100%] text-center px-[20px]">
              <AccordionButton className="flex flex-row items-center justify-center w-[100%] text-center">
                <h1 className="text-[16px] font-[400] pr-[10px]">
                  Select one of the options below to launch your validator
                </h1>
                <div className="dark:bg-white bg-black dark:text-black text-white rounded-[50%] w-[16px] h-[16px] flex flex-col items-center justify-center">
                  <AccordionIcon className="" />
                </div>
              </AccordionButton>
            </h2>
            <AccordionPanel className="flex flex-col lg:flex-row items-center flex-wrap w-[100%] bg-white dark:bg-[#3a5163] rounded-b-[20px] pt-[20px] pb-[30px]">
              {v.map((us, id) => (
                <a
                  key={id}
                  className="w-[100%] mb-[20px] lg:w-[33%] flex flex-col items-center justify-center"
                  href={us.link}
                >
                  {theme === "dark" ? us.whiteIcon : us.blackIcon}
                </a>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </section>

      <section className="flex flex-col items-start w-[100%] lg:w-[900px] pt-[30px]">
        <section className="flex flex-row items-center justify-between w-[100%]">
          {top.map((us, id) => (
            <div
              className={` text-[1.5rem] font-[400] w-[49%] rounded-t-[.75rem] py-[10px] cursor-pointer
              ${
                page === us.id
                  ? "dark:bg-[#3a5163] bg-white"
                  : "dark:bg-[#23323d] bg-[#d6d5c7]"
              }`}
              key={id}
              onClick={() => {
                setPage(us.id);
              }}
            >
              {us.title}
            </div>
          ))}
        </section>

        <section className="flex flex-row items-center w-[100%]">
          {page === 1 ? payg : stake}
        </section>
      </section>

      <h1 className="text-[1.5rem] font-[500] py-[30px]">My Validators</h1>
      <h1 className="text-[16px] font-[400]">
        You don't have any validators yet
      </h1>
    </body>
  );
};

export default Validators;
